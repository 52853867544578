import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paginator from 'paginator';

const Pager = ({
  items,
  render,
  pageRange,
  scrollToTopOnPageChange,
  showJumpToPage,
}) => {
  const [ activePage, setActivePage ] = useState(1);
  const [ itemsPerPage, setItemsPerPage ] = useState(15);

  // this sets the user back to page 1 if they change the sort/filter.
  // or if they change the items per page
  useEffect(() => {
    setActivePage(1);
  }, [ items, itemsPerPage ]);

  const handlePageChange = (page) => {
    setActivePage(page);
    if (scrollToTopOnPageChange) {
      window.scrollTo(0, 0);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
  };

  const {
    first_page: firstPage,
    last_page: lastPage,
    has_previous_page: hasPreviousPage,
    has_next_page: hasNextPage,
    total_pages: totalPages,
  } = new Paginator(itemsPerPage, pageRange).build(items.length, activePage);

  const start = (activePage - 1) * itemsPerPage;
  const itemsSlice = items.slice(start, start + itemsPerPage);

  const pageButtons = [];
  for (let i = firstPage; i <= lastPage; i++) {
    pageButtons.push(
      <button
        className='individual-page-button'
        key={'page_' + i}
        data-public
        style={{
          backgroundColor: i === activePage ? '#c2f0ee' : 'white',
        }}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </button>,
    );
  }

  const options = [];
  for (let i = 1; i <= totalPages; i++) {
    options.push({
      value: i,
      text: i,
    });
  }

  return (
    <div>
      {render(itemsSlice, start)}
      <div className='pager-buttons-container'>
        <div className='pager-font'>
          <span className='lighter'>Rows per page:</span>
          <select
            className='pager-select'
            onChange={handleItemsPerPageChange}
          >
            <option value='15'>15</option>
            <option value='30'>30</option>
            <option value='50'>50</option>
            <option value='100'>100</option>
          </select>
        </div>
        {pageButtons}
        <div className='pager-font'>
          .. of {`${totalPages}`}</div>
        <button
          data-public
          disabled={!hasPreviousPage}
          className='pager-navigate-chevron'
          onClick={() => handlePageChange(activePage - 1)}
        >
          <img alt='left-arrow' src={require('../../theme/assets/chevron-left-filled.png')} />
        </button>
        <button
          data-public
          disabled={!hasNextPage}
          className='pager-navigate-chevron'
          onClick={() => handlePageChange(activePage + 1)}
        >
          <img alt='right-arrow' src={require('../../theme/assets/chevron-right-filled.png')} />
        </button>
        {showJumpToPage && (
          <select
            data-public
            style={{ marginLeft: '10px' }}
            value={activePage}
            onChange={(e) => {
              handlePageChange(Number(e.target.value));
            }}
          >
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

Pager.propTypes = {
  items: PropTypes.array,
  render: PropTypes.func.isRequired,
  pageRange: PropTypes.number.isRequired,
  scrollToTopOnPageChange: PropTypes.bool,
  showJumpToPage: PropTypes.bool,
};

export default Pager;
