import {
  useState, useEffect,
} from 'react';
import { CSVLink } from 'react-csv';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isClimateSurvey } from '../../../../utils';

import ORGANIZATION_ASSESSMENTS_QUERY from '../../../../graphql/queries/organization/assessments';
import ORGANIZATIONS_SCORES_QUERY from '../../../../graphql/queries/components/pages/Dashboard/Classroom';

import Checkbox from '../../../Checkbox';
import LoadingPane from '../../../LoadingPane';
import SearchField from '../../../SearchField';
import TableView from '../../../TableView';
import SummaryTooltip from './Tooltip';

const SummaryClassroom = ({
  assessment,
  school,
  setParentTimepoint,
  setInSummaryPlot,
}) => {
  const [ time, setTime ] = useState();
  const [ sortKey, setSortKey ] = useState('name');
  const [ sortDirection, setSortDirection ] = useState('asc');
  const [ filterList, setFilterList ] = useState([]);
  const [ search, setSearch ] = useState();
  const [ classesShown, setClassesShown ] = useState([]);

  const {
    data: assessmentsData,
    loading: assessmentsLoading,
  } = useQuery(ORGANIZATION_ASSESSMENTS_QUERY, { variables: { orgIDs: [ school?.id ] } });

  const {
    data: scoresData,
    error: scoresError,
    loading: scoresLoading,
  } = useQuery(ORGANIZATIONS_SCORES_QUERY, {
    variables: {
      orgIDs: [ school?.id ],
      assessmentID: assessment?.id,
    },
    fetchPolicy: 'no-cache',
  });

  const classesWithScores = scoresData?.getOrganizations[0].childOrgs[0].classes?.filter((c) => filterByAssessmentId(c, time)).map((c) => {
    const _class = { ...c };
    c.statistics?.filter((s) => s.timepoint === time).forEach((stat) => {
      _class[stat.category] = stat.average ? Number.isInteger(stat.average) ? stat.average : stat.average.toFixed(2) : '-';
    });
    return _class;
  });

  useEffect(() => {
    setSortKey('name');
    setSortDirection('asc');
    setFilterList([]);
    setSearch('');
    setClassesShown([ classesWithScores ]);
  }, [
    assessment,
    school,
    time,
    scoresData,
  ]);

  const isElem = [
    '1',
    '2',
    '3',
  ].includes(assessment.id);

  const isClimate = isClimateSurvey(assessment);

  const isHighSchool = [
    '5',
    '84',
    '85',
  ].includes(assessment.id);

  const scoreBandsHashElem = {
    aboveAvg: {
      min: 115,
      max: Infinity,
    },
    avg: {
      min: 90,
      max: 115,
    },
    belowAvg: {
      min: 70,
      max: 90,
    },
    wellBelowAvg: {
      min: 0,
      max: 70,
    },
  };

  const scoreBandsHashClimate = {
    agreeALot: {
      min: 4,
      max: Infinity,
    },
    agreeALittle: {
      min: 3,
      max: 4,
    },
    disagreeALittle: {
      min: 2,
      max: 3,
    },
    disagreeALot: {
      min: 1,
      max: 2,
    },
  };

  const scoreBandHashHighSchool = {
    highlyConfident: {
      min: 5,
      max: Infinity,
    },
    confident: {
      min: 4,
      max: 5,
    },
    sometimesConfident: {
      min: 3,
      max: 4,
    },
    notVeryConfident: {
      min: 2,
      max: 3,
    },
    notConfident: {
      min: 1,
      max: 2,
    },
  };

  useEffect(() => {
    let tempFilteredClasses = classesWithScores || [];

    if (search) {
      tempFilteredClasses = tempFilteredClasses.filter((classroom) => classroom.name.toLowerCase().includes(search.toLowerCase()));
    }

    if (filterList.length > 0) {
      const groupingsFilterHash = {};
      filterList.forEach((filter) => {
        const filterSplit = filter.split('-');
        const grouping = filterSplit[0];
        const scoreBand = filterSplit[1];
        if (!groupingsFilterHash[grouping]) {
          groupingsFilterHash[grouping] = [ scoreBand ];
        } else {
          groupingsFilterHash[grouping].push(scoreBand);
        }
      });

      for (const grouping in groupingsFilterHash) {
        tempFilteredClasses = tempFilteredClasses.filter((classObj) => {
          return groupingsFilterHash[grouping].some((scoreBand) => {
            const scoreBandsHash = isElem ? scoreBandsHashElem : isClimate ? scoreBandsHashClimate : isHighSchool ? scoreBandHashHighSchool : {};
            const minScore = scoreBandsHash[scoreBand]?.min || 0;
            const maxScore = scoreBandsHash[scoreBand]?.max || Infinity;
            const schoolScore = classObj[grouping];
            return schoolScore >= minScore && schoolScore < maxScore;
          });
        });
      };
    };

    tempFilteredClasses = _.orderBy(tempFilteredClasses, sortKey, sortDirection);
    setClassesShown(tempFilteredClasses);
  }, [
    filterList,
    search,
    sortKey,
    sortDirection,
  ]);

  if (scoresError) window.alert(scoresError);
  if ((assessmentsLoading || scoresLoading) || (!assessmentsData || !scoresData)) return <LoadingPane />;
  const assessments = assessmentsData?.getOrgAssessments;
  if (!time) {
    const tempTime = (assessments?.length > 0) ? assessments[0].timepoint : null;
    setTime(tempTime);
    setInSummaryPlot(false);
    setParentTimepoint(tempTime);
  }

  function filterByAssessmentId (classroom, time) {
    const scores = classroom.statistics ? classroom.statistics.filter((stat) => stat.timepoint === time) : [];

    return (scores.length > 0) ? classroom : null;
  };

  const categories = assessment?.categories
    ? assessment.categories.split(',').map((category) => {
      const strParts = category.split('|');

      return {
        key: strParts[0],
        name: strParts[1] ?? strParts[0],
      };
    })
    : [];

  const csvHeaders = [
    'Classroom',
    'Students',
    ...categories.map((c) => c.name),
  ];
  const csvSchools = classesWithScores?.map((s) => {
    const scores = [];

    categories.forEach((c) => {
      if (s[c.key]) {
        scores.push(s[c.key]);
      } else {
        scores.push(0);
      }
    });

    return [
      s.name,
      s.studentCount,
      ...scores,
    ];
  });

  const csvData = [ csvHeaders, ...csvSchools ];

  function generateTimepoints () {
    const arr = [];
    const max = Math.max(...assessments?.map((a) => a.timepoint));
    let idx = 1;
    while (idx <= max) {
      arr.push(idx);
      idx++;
    }
    return arr;
  }

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  const handleCheckboxChange = (value) => {
    if (filterList.includes(value)) {
      setFilterList(filterList.filter((filter) => filter !== value));
    } else {
      setFilterList([ ...filterList, value ]);
    }
  };

  return (
      <div className='summary-classroom-view full-screen-view'>
      <ul className='times-frame'>
        {generateTimepoints().map((timepoint) => {
          const selected = (time === timepoint);

          return (
            <Checkbox
              key={timepoint}
              onChange={() => {
                setTime(timepoint);
                setInSummaryPlot(false);
                setParentTimepoint(timepoint);
              }}
              checked={selected}
              title={`Time ${timepoint}`}
              type='radio'
            />
          );
        })}
      </ul>

      <div className='table-frame'>
        <div className='table-toolbar-frame'>
          <SearchField
            placeholder='Classroom Name'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />

          {filterList.length > 0 &&
            <button
            className='clear-all-filters-btn'
            onClick={() => setFilterList([])}>
            CLEAR FILTERS</button>}

            <CSVLink
              className='table-toolbar-button'
              data={csvData}
              filename={`${assessment.name.toUpperCase()} ${(school.name).toUpperCase()} ${new Date().toLocaleDateString('en-US')}`.replace(/ /g, '-')}
            >export report</CSVLink>
        </div>

        <TableView
          headers={[
            {
              label: 'Classroom',
              key: 'name',
            },
            {
              label: 'Number of Students',
              key: 'studentCount',
            },
            ...categories.map((c) => ({
              key: c.key,
              label: c.name,
            })),
          ]}
          data={classesShown}
          assessment={assessment}
          dataMappings={[
            { key: 'name' },
            // { key: 'grade' },
            { key: 'studentCount' },
            ...categories.map((c) => ({
              key: c.key,
              score: c.key,
            })),
          ]}
          sortKey={sortKey}
          sortDirection={sortDirection}
          onSort={handleSort}
          onFilter={handleCheckboxChange}
          filterList={filterList}
        />

        <SummaryTooltip
          isClimate={isClimate}
          isHighSchool={isHighSchool}
        />
      </div>
    </div>
  );
};

SummaryClassroom.propTypes = {
  assessment: PropTypes.object.isRequired,
  school: PropTypes.object.isRequired,
  setParentTimepoint: PropTypes.func,
  setInSummaryPlot: PropTypes.func,
};

export default SummaryClassroom;
