import {
  useRef, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import to from 'await-to-js';

import ORGANIZATIONS_QUERY from '../../graphql/queries/organizations';
import ASSESSMENTS_QUERY from '../../graphql/queries/assessments';
import USER_QUERY from '../../graphql/queries/components/pages/UserReport/User';
import USER_RESPONSES_QUERY from '../../graphql/queries/components/pages/UserReport/UserResponses';

import LoadingPane from '../../components/LoadingPane';
import SelectMenu from '../../components/SelectMenu';
import TabsView from '../../components/TabsView';

import FilterReportStats from '../../components/pages/Dashboard/FilterReportStats';
import SummaryDistrict from '../../components/pages/Dashboard/Summary/District';
import SummarySchool from '../../components/pages/Dashboard/Summary/School';
import SummaryClassroom from '../../components/pages/Dashboard/Summary/Classroom';
import SummaryStudent from '../../components/pages/Dashboard/Summary/Student';
import SummaryPlots from '../../components/pages/Dashboard/Summary/Plots';

import getAuthToken, { parseTokenData } from '../../get-auth-token';

const Dashboard = () => {
  const [ onload, setOnload ] = useState(true); // to handle student redirect without popping view
  const [ districts, setDistricts ] = useState([]);
  const [ district, setDistrict ] = useState();
  const [ schools, setSchools ] = useState();
  const [ school, setSchool ] = useState();
  const [ classroom, setClassroom ] = useState();
  const [ assessments, setAssessments ] = useState([]);
  const [ assessment, setAssessment ] = useState();
  const [ tab, setTab ] = useState(0);
  const [ expand, setExpand ] = useState(true);
  const [ parentTimepoint, setParentTimepoint ] = useState();
  const [ parentTimepoints, setParentTimepoints ] = useState([]);
  const [ inSummaryPlot, setInSummaryPlot ] = useState(false);

  const headerRef = useRef();
  const tabs = generateTabs();
  const navigate = useNavigate();

  const {
    data: orgData,
    error: orgErr,
    loading: orgLoading,
    refetch,
  } = useQuery(ORGANIZATIONS_QUERY, { fetchPolicy: 'no-cache' });

  const {
    data: user,
    loading: userLoading,
  } = useQuery(USER_QUERY, { variables: { userID: '' } });

  const [ getUserAssessments ] = useLazyQuery(USER_RESPONSES_QUERY, { variables: { userID: '' } });

  const [
    getAssessments,
    {
      data: assessmentsData,
      error: assessmentsErr,
      loading: assessmentsLoading,
    },
  ] = useLazyQuery(ASSESSMENTS_QUERY);

  const loading = orgLoading || assessmentsLoading || userLoading;
  const apiError = orgErr || assessmentsErr;

  async function findInsightsUser () {
    const [ tokenErr, token ] = await to(getAuthToken());

    if (tokenErr) {
      console.log(tokenErr);
    } else {
      const tokenData = parseTokenData(token);

      return {
        ...user.getUser,
        token: tokenData,
      };
    }
  }

  async function checkUserRole (orgs) {
    const [ userError, user ] = await to(findInsightsUser());
    const [ userAssessmentsError, userAssessments ] = await to(getUserAssessments({ variables: { userID: user?.id } }));

    if (userError || userAssessmentsError) {
      window.alert('There was an issue loading your user data.');
    }

    const assessments = userAssessments?.data?.getUserAssessments ?? [];

    if (user.token['7m:roles'].includes('student')) {
      if (assessments.length === 0) {
        window.alert('User does not have any data to view.');
      } else {
        navigate(`/users/${user.id}/assessment/${assessments[0].assessmentID}/schoolYear/${assessments[0].schoolYear}/timepoint/${assessments[0].timepoint}`);
      }
    } else {
      setup(orgs);
      setOnload(false);
    }
  }

  useEffect(() => {
    const orgs = orgData?.getOrganizations ?? [];

    if (!orgLoading && !userLoading) {
      checkUserRole(orgs);
    }

    // if we've never set the districts before, do it now
    if (!districts.length) setDistricts(_.sortBy(orgs, 'name'));
  }, [
    orgData,
    orgLoading,
    userLoading,
  ]);

  useEffect(() => {
    if (!assessmentsLoading) {
      const sortedAssessments = _.sortBy(assessmentsData?.getAssessmentsByOrgIds || [], 'name');
      setAssessments(sortedAssessments);
    }
  }, [ assessmentsData, assessmentsLoading ]);

  useEffect(() => {
    if (assessment && schools) {
      refetch({
        orgIDs: _.map(schools, 'id'),
        assessmentID: assessment.id,
      }).then((response) => setup(response.data.getOrganizations));
    }
  }, [ assessment ]);

  function setup (data) {
    const sortedOrgs = _.sortBy(data, 'name');
    const org = district ? sortedOrgs.find((o) => o.id === district.id) : sortedOrgs[0];

    setDistrict(org);
    setSchools(org?.childOrgs || []);
    getAssessments({ variables: { orgIDs: _.map(org.childOrgs, 'id') } });
  }

  function generateTabs () {
    const tabs = [
      {
        name: 'summary plots',
        component: <SummaryPlots
          assessment={assessment}
          district={district}
          school={school}
          classroom={classroom}
          schools={schools}
          setParentTimepoints={setParentTimepoints}
          setInSummaryPlot={setInSummaryPlot}
        />,
      },
    ];

    const showDistrict = (assessment !== undefined) && (district === undefined) && (school === undefined) && (classroom === undefined);
    const showSchool = (district !== undefined) && (school === undefined);
    const showClassroom = (school !== undefined) && (school.classes) && (classroom === undefined);

    if (showDistrict) {
      tabs.push({
        name: 'summary scores by district',
        component: <SummaryDistrict />,
      });
    } else if (showSchool) {
      tabs.push({
        name: 'summary scores by school',
        component:
          <SummarySchool
            assessment={assessment}
            district={district}
            school={school}
            schoolsList={schools}
            setParentTimepoint={setParentTimepoint}
            setInSummaryPlot={setInSummaryPlot}
          />,
      });
    } else if (showClassroom) {
      tabs.push({
        name: 'summary scores by classroom',
        component:
          <SummaryClassroom
            assessment={assessment}
            school={school}
            setParentTimepoint={setParentTimepoint}
            setInSummaryPlot={setInSummaryPlot}
          />,
      });
    }

    tabs.push({
      name: 'student level data',
      component:
        <SummaryStudent
          classroom={classroom}
          assessment={assessment}
          district={district}
          school={school}
          stickyNode={headerRef}
          schools={schools}
          setParentTimepoint={setParentTimepoint}
          setInSummaryPlot={setInSummaryPlot}
        />,
    });

    return tabs;
  }

  function formatOptions (arr, hint) {
    const options = _.sortBy((arr || []).map(({ id, name }) => ({
      name,
      value: id,
    })), 'name');
    return (hint) ? [ hint, ...options ] : options;
  }

  async function handleDistrictChange (e) {
    const selected = districts.find((d) => d.id === e);

    setDistrict(selected);
    setSchools(selected.childOrgs);
    getAssessments({ variables: { orgIDs: _.map(selected.childOrgs, 'id') } });
    setSchool(undefined);
    setClassroom(undefined);
    if (!selected) resetSelections();
  }

  function resetSelections () {
    setDistrict(districts[0]);
    setAssessment(undefined);
    setSchools(districts[0].childOrgs);
    setSchool(undefined);
    setClassroom(undefined);
    getAssessments({ variables: { orgIDs: _.map(districts[0].childOrgs, 'id') } });

    if (tab >= (tabs.length - 1)) setTab(0);
  }

  if (onload || loading) return <LoadingPane />;

  return (
    <div className='dashboard-view full-screen-view scroll'>
      {/* <NavigationSlider/> */}

      <div className='logo'>
        <img
          alt='logo'
          src={require('../../theme/assets/insights-logo.png')}
          />
      </div>
      {(!!apiError) && (
        <p>There was an error on the page: {apiError.message}</p>
      )}

      {(!apiError) && (
        <div className='content-frame'>
          <div className='top-section' ref={headerRef}>
            <button className='expand-button' onClick={() => setExpand(!expand)}>{expand ? 'Collapse' : 'Expand'}</button>

            <div className={`centered-frame ${expand ? '' : 'collapse'}`}>
              <div className='selection-menus-frame'>
                <SelectMenu
                  className='selection-menu'
                  header='Select District'
                  onChange={handleDistrictChange}
                  options={formatOptions(districts)}
                  value={district?.id}
                />

                <SelectMenu
                  className='selection-menu'
                  disabled={(!schools?.length)}
                  header='Select School'
                  onChange={(e) => {
                    const selected = schools.find((o) => o.id === e);

                    setSchool(selected);
                    setClassroom(undefined);
                    getAssessments({ variables: { orgIDs: (selected) ? [ selected.id ] : _.map(district.childOrgs, 'id') } });
                  }}
                  options={formatOptions(schools)}
                  value={school?.id || ''}
                />

                <SelectMenu
                    className='selection-menu'
                    disabled={!school?.classes}
                    header='Select Classroom'
                    onChange={(e) => {
                      if (school) setSchool(school);
                      setClassroom(school.classes.find((c) => c.id === e));
                    }}
                    options={formatOptions(school?.classes.filter((c) => c.statistics.length > 0))}
                    value={classroom?.id || ''}
                />

                <SelectMenu
                  className='selection-menu'
                  header='Select Assessment'
                  disabled={!assessments?.length}
                  onChange={(e) => setAssessment(assessments.find((o) => o.id === e))}
                  options={formatOptions(assessments)}
                  value={assessment?.id || ''}
                />

                {(district !== undefined) && <button className='clear-button' onClick={resetSelections}>CLEAR</button>}
              </div>

              <FilterReportStats
                assessment={assessment}
                districts={districts}
                district={district}
                school={school}
                classroom={classroom}
                parentTimepoint={parentTimepoint}
                parentTimepoints={parentTimepoints}
                inSummaryPlot={inSummaryPlot}
              />
            </div>
          </div>

          {assessment
            ? <TabsView
                stickyNode={headerRef}
                onChange={(t) => setTab(t)}
                tabs={tabs}
                tab={tab}
                setTab={setTab}
              />
            : <div className='hint-label'>Select an assessment to view results</div>
          }
      </div>
      )}
    </div>
  );
};

export default Dashboard;
