import PropTypes from 'prop-types';
import { useEffect } from 'react';

const TabsView = (props) => {
  const {
    onChange,
    // stickyNode,
    setTab,
    tabs,
    tab,
  } = props;

  useEffect(() => {
    if (tab >= tabs.length) {
      console.log(`reset tab index: ${tab} --> ${tabs.length - 1}`);
      setTab(tabs.length - 1);
    }
  }, [
    tab,
    tabs,
    setTab,
  ]);

  if (!tabs) return;

  const selectedTab = tabs[tab];

  return (
    <div className='tabs-view'>
      <ul className='tabs-list-view' /* style={{ top: stickyNode.current?.clientHeight }} */>
        {tabs.map((t, i) => {
          const selected = (tab === i);

          return (
            <li
              className={`tab-cell ${selected ? 'selected' : ''}`}
              key={t.name}
              onClick={() => onChange(i)}
            >{t.name}</li>
          );
        })}
      </ul>

      <div className='tab-content-frame'>
        {selectedTab && selectedTab.component}
      </div>
    </div>
  );
};

TabsView.propTypes = {
  onChange: PropTypes.func,
  setTab: PropTypes.func,
  tab: PropTypes.number,
  tabs: PropTypes.array,
};

export default TabsView;
