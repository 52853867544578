import PropTypes from 'prop-types';

const FilterReportStats = (props) => {
  const {
    assessment,
    districts,
    district,
    school,
    classroom,
    parentTimepoint,
    parentTimepoints,
    inSummaryPlot,
  } = props;
  const schoolCount = district ? district.childOrgs?.length ?? 0 : districts.reduce((partialSum, d) => partialSum + (d.childOrgs?.length ?? 0), 0);
  const studentCount = classroom?.studentCount ?? school?.studentCount ?? district?.studentCount;
  const timepoints = inSummaryPlot ? parentTimepoints : [ parentTimepoint ];

  function calculateCompletionRate () {
    const mapCompletionRate = (stats) => Math.min(...stats.filter((s) => timepoints.includes(s.timepoint)).map((s) => s.completionRate)); ;

    let score = 0;

    /* --- scope to narrowest selected group and timepoints --- */
    if (timepoints.length === 0) {
      // handle empty timepoints edge case
      return score;
    } else if (classroom?.statistics) {
      score = mapCompletionRate(classroom.statistics);
    } else if (school?.statistics) {
      score = mapCompletionRate(school.statistics);
    } else if (district?.statistics) {
      score = mapCompletionRate(district.statistics);
    }

    return score;
  }

  return (
    <div className='filter-report-stats-view'>
      <div className='breadcrumb-view'>
        {/* hint label */}
        {!district &&
          <div className='breadcrumb-frame first'>
            <div className='breadcrumb-hint'>No information yet.</div>
          </div>
        }

        {assessment &&
          <div className='breadcrumb-frame first'>
            <img
              alt='breadcrumb-icon'
              className='breadcrumb-icon'
              src={require('../../../theme/assets/breadcrumb-assessment-icon.png')}
            />

            <div className='breadcrumb-hint'>Assessment</div>
            <div className='breadcrumb-value'>{assessment?.name}</div>
          </div>
        }

        {district &&
          <div className='breadcrumb-frame'>
            <img
              alt='breadcrumb-icon'
              className='breadcrumb-icon'
              src={require('../../../theme/assets/breadcrumb-district-icon.png')}
            />

            <div className='breadcrumb-hint'>District</div>
            <div className='breadcrumb-value'>{district?.name}</div>
          </div>
        }

        {school &&
          <div className='breadcrumb-frame'>
            <img
              alt='breadcrumb-icon'
              className='breadcrumb-icon'
              src={require('../../../theme/assets/breadcrumb-school-icon.png')}
            />

            <div className='breadcrumb-hint'>School</div>
            <div className='breadcrumb-value'>{school?.name}</div>
          </div>
        }

        {classroom &&
          <div className='breadcrumb-frame'>
            <img
              alt='breadcrumb-icon'
              className='breadcrumb-icon'
              src={require('../../../theme/assets/breadcrumb-classroom-icon.png')}
            />

            <div className='breadcrumb-hint'>Classroom</div>
            <div className='breadcrumb-value'>{classroom?.name}</div>
          </div>
        }
      </div>

      <div className='stats-frame'>
        {!district &&
          <div className='stat-frame first'>
            <img alt='breadcrumb-icon' src={require('../../../theme/assets/stat-district-icon.png')} />

            <div className='right-section'>
              <div className='stat-header'>Number of Districts</div>
              <div className='stat-value districts'>{districts.length.toLocaleString()}</div>
            </div>
          </div>
        }

        {!school &&
          <div className='stat-frame'>
            <img alt='breadcrumb-icon' src={require('../../../theme/assets/stat-school-icon.png')} />

            <div className='right-section'>
              <div className='stat-header'>Number of Schools</div>
              <div className='stat-value schools'>{schoolCount.toLocaleString()}</div>
            </div>
          </div>
        }

        <div className='stat-frame'>
          <img alt='breadcrumb-icon' src={require('../../../theme/assets/stat-student-icon.png')} />

          <div className='right-section'>
            <div className='stat-header'>Number of Students</div>
            <div className='stat-value students'>{((classroom ? classroom.studentCount : (school ? school.studentCount : (district ? district.studentCount : studentCount))) || 0).toLocaleString()}</div>
          </div>
        </div>

        <div className='stat-frame'>
          <img alt='breadcrumb-icon' src={require('../../../theme/assets/completion-rate-icon-purple.png')} />

          <div className='right-section'>
            <div className='stat-header'>Completion Rate</div>
            <div className='stat-value purple'>
              {Math.round(calculateCompletionRate())}
              <div className='pct'>%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FilterReportStats.propTypes = {
  assessment: PropTypes.object,
  districts: PropTypes.array,
  district: PropTypes.object,
  school: PropTypes.object,
  classroom: PropTypes.object,
  parentTimepoint: PropTypes.number,
  parentTimepoints: PropTypes.array,
  inSummaryPlot: PropTypes.bool,
};

export default FilterReportStats;
