import PropTypes from 'prop-types';

const fourColorRange = [
  'orange',
  'light-orange',
  'light-green',
  'light-green-2',
];

const fiveColorRange = [
  'orange',
  'light-orange',
  'light-grey',
  'light-green',
  'light-green-2',
];

const ScoredCategory = (props) => {
  const {
    score,
    label,
    scoreRanges,
    isEELEMS,
  } = props;

  const colorRange = (scoreRanges.length === 4) ? fourColorRange : fiveColorRange;
  const colorIndex = scoreRanges.findIndex((range) => (score >= range[0] && score < range[1]));
  const color = colorRange[colorIndex];
  return (
    <div className={`scored-category ${color}`}>{label ?? `${score}${isEELEMS ? '' : '/' + scoreRanges.length}`}</div>
  );
};

ScoredCategory.propTypes = {
  score: PropTypes.number,
  label: PropTypes.string,
  scoreRanges: PropTypes.array.isRequired,
  isEELEMS: PropTypes.bool.isRequired,
};

ScoredCategory.defaultProps = {
  score: 0,
};

export default ScoredCategory;
