import PropTypes from 'prop-types';

const ToolTip = (props) => {
  const { data } = props;

  return (
    <ul className='tooltip-view'>
      {data.map((obj) => {
        const {
          hex, title, subtitle,
        } = obj;

        return (
          <li className='tooltip-cell' key={title}>
            {hex && <div className='hex' style={{ background: hex }} />}

            <div className='title-frame'>
              <div className='title'>{title}</div>
              <div className='subtitle'>{subtitle}</div>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

ToolTip.propTypes = {
  data: PropTypes.array,
};

export default ToolTip;
