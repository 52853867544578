import PropTypes from 'prop-types';

import ScoredCategory from '../../TableView/ScoredCategory';
import SummaryTooltip from '../Dashboard/Summary/Tooltip';

const UserDomains = (props) => {
  const {
    selectedAssessment,
    isClimate,
    isHighSchool,
    isEELEMS,
  } = props;

  const categoryNames = {
    overall: 'Overall Score',
    er: 'Emotion Recognition',
    sps: 'Social Problem-Solving',
    spt: 'Social Perspective-Taking',
    sc: 'Self Control',
    sa: 'Self-Awareness',
    sm: 'Self-Management',
    so: 'Social Awareness',
    rs: 'Relationship Skills',
    rd: 'Responsible Decision-Making',
    uo: 'Understanding Others',
    is1: 'I feel safe',
    is2: 'Rules are fair',
    sb1: 'Teachers care about me',
    sb2: 'Kids care about me',
    ss1: 'Teachers help me learn',
    ss2: 'Teachers encourage',
    rel_skills: 'Relationship Skills',
    resp_dec_making: 'Respectful Decisions',
    self_awareness: 'Self Awareness',
    self_management: 'Self Management',
    social_awareness: 'Social Awareness',
  };

  const subCategories = [
    'dg',
    'ft',
    'a',
    'gp',
    'sp',
    'erk',
    'srsc',
    'consequence',
    'goal',
    'solution',
    'cer',
  ];

  const categories = selectedAssessment?.scores?.map((score) => score.category);

  const categoryIcons = {
    'Self Awareness': require('../../../theme/assets/self-awareness-icon.png'),
    sa: require('../../../theme/assets/self-awareness-icon.png'),
    'Self Management': require('../../../theme/assets/self-management-icon.png'),
    sm: require('../../../theme/assets/self-management-icon.png'),
    'Social Awareness': require('../../../theme/assets/social-awareness-icon.png'),
    so: require('../../../theme/assets/social-awareness-icon.png'),
    'Relationship Skills': require('../../../theme/assets/relationship-skills-icon.png'),
    rs: require('../../../theme/assets/relationship-skills-icon.png'),
    'Responsible Decision Making': require('../../../theme/assets/responsible-decision-making-icon.png'),
    rd: require('../../../theme/assets/responsible-decision-making-icon.png'),
    'Growth Mindset': require('../../../theme/assets/growth-mindset-icon.png'),
    Hope: require('../../../theme/assets/hope-icon.png'),
    self_awareness: require('../../../theme/assets/self-awareness-icon.png'),
    self_management: require('../../../theme/assets/self-management-icon.png'),
    social_awareness: require('../../../theme/assets/social-awareness-icon.png'),
    rel_skills: require('../../../theme/assets/relationship-skills-icon.png'),
    resp_dec_making: require('../../../theme/assets/responsible-decision-making-icon.png'),
  };

  const overall = categories?.find((c) => c === 'overall');
  const overallScore = selectedAssessment.scores?.find((s) => s.category === 'overall');
  const scoreRanges = (selectedAssessment.assessmentID === 4)
    ? [
        [ 1, 2 ],
        [ 2, 3 ],
        [ 3, 4 ],
        [ 4, Infinity ],
      ]
    : ([
        5,
        84,
        85,
      ].includes(selectedAssessment.assessmentID))
        ? [
            [ 1, 2 ],
            [ 2, 3 ],
            [ 3, 4 ],
            [ 4, 5 ],
            [ 5, Infinity ],
          ]
        : [
            [ 0, 70 ],
            [ 70, 90 ],
            [ 90, 115 ],
            [ 115, Infinity ],
          ];

  return (
    <div className='user-report-view full-screen-view'>
      <div className='user-report-tooltip'>
        <SummaryTooltip
          isClimate={isClimate}
          isHighSchool={isHighSchool}
        />
      </div>
      {selectedAssessment.scores?.length
        ? <div className='content-frame scroll'>
        {overall &&
          <div className='category-cell overall'>
            <div className='name'>{categoryNames.overall}</div>

            <ScoredCategory
              score={overallScore?.score}
              // label={overallScore?.score ?? 0}
              scoreRanges={scoreRanges}
              isEELEMS={isEELEMS}
            />
          </div>
        }

        <ul className='categories-list-view'>
          {categories.filter((c) => c !== 'overall' && !subCategories.includes(c)).map((category) => {
            const score = selectedAssessment.scores.find((s) => s.category === category);

            return (
              <li className='category-cell' key={score.category}>
                <div className='name'>
                  {categoryIcons[category] && <img alt='breadcrumb-icon' src={categoryIcons[category]} className='competency-icon'/>}
                  {<span className='competency-category-name'>{categoryNames[score.category] ?? score.category}</span>}
                </div>

                <ScoredCategory
                  score={score?.score}
                  scoreRanges={scoreRanges}
                  isEELEMS={isEELEMS}
                />
              </li>
            );
          })}
        </ul>
      </div>
        : <div className='hint-label'>no scores available for this assessment</div>}
    </div>
  );
};

UserDomains.propTypes = {
  selectedAssessment: PropTypes.object.isRequired,
  isClimate: PropTypes.bool.isRequired,
  isHighSchool: PropTypes.bool.isRequired,
  isEELEMS: PropTypes.bool.isRequired,
};

export default UserDomains;
