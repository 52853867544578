import PropTypes from 'prop-types';
import SelectSearch from 'react-select-search';

const SelectMenu = (props) => {
  const {
    className,
    disabled,
    header,
    onChange,
    options,
    value,
  } = props;

  return (
    <div className={`select-menu-view ${className || ''} ${disabled ? 'disabled' : ''}`}>
    <div className='menu-header'>{header}</div>

    <SelectSearch
      emptyMessage='No results found.'
      disabled={disabled}
      options={options}
      onChange={onChange}
      placeholder={header || 'Select Value'}
      search={true}
      value={value}
    />

    <div className={`arrow-icon ${value ? '' : 'down'}`}>

    <img
    alt='arrow-icon'

    src={require('../../theme/assets/up-arrow-grey-icon-2x.png')}
    />
    </div>
    </div>
  );
};

SelectMenu.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default SelectMenu;
