import { useState } from 'react';

// import Checkbox from '../../../Checkbox';
import SearchField from '../../../SearchField';
import TableView from '../../../TableView';
import SummaryTooltip from './Tooltip';

const SummaryDistrict = () => {
  // const [ time, setTime ] = useState();
  const [ search, setSearch ] = useState();

  // CURRENTLY NO SORTING OR FILTERING HERE

  return (
    <div className='summary-district-view full-screen-view'>
      <div className='times-frame'>
        {/* <Checkbox
          title='Time 1'
          subtitle='Aug 23, 2022'
          type='radio'
          onChange={(e) => setTime(0)}
          checked={time === 0}
        />

        <Checkbox
          className='middle'
          title='Time 2'
          subtitle='Aug 23, 2022'
          type='radio'
          onChange={(e) => setTime(1)}
          checked={time === 1}
        />

        <Checkbox
          title='Time 3'
          subtitle='Aug 23, 2022'
          type='radio'
          onChange={(e) => setTime(2)}
          checked={time === 2}
        /> */}
      </div>

      <div className='table-frame'>
        <div className='table-toolbar-frame'>
          <SearchField
            placeholder='Teacher, Grade, etc...'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />

          <button className='table-toolbar-button'>export report</button>
        </div>

        <TableView
          headers={[
            {
              label: 'District',
              key: 'district',
            },
            {
              label: 'Number of Schools',
              key: 'schools',
            },
            {
              label: 'Number of Classrooms',
              key: 'classrooms',
            },
            {
              label: 'Number of Students',
              key: 'students',
            },
            {
              label: 'Overall Score',
              key: 'score',
            },
            {
              label: 'Emotion Recognition',
              key: 'er',
            },
            {
              label: 'Social Perspective-Taking',
              key: 'spt',
            },
            {
              label: 'Social Problem-Solving',
              key: 'sps',
            },
            {
              label: 'Self Control',
              key: 'sc',
            },
            'District',
            'Number of Schools',
            'Number of Classrooms',
            'Number of Students',
            'Overall Score',
            'Emotion Recognition',
            'Social Perspective-Taking',
            'Social Problem-Solving',
            'Self Control',
          ]}
          data={fauxData}
          dataMappings={[
            { key: 'district' },
            { key: 'schools' },
            { key: 'classrooms' },
            { key: 'students' },
            { key: 'score' },
            {
              key: 'er',
              score: true,
            },
            {
              key: 'spt',
              score: true,
            },
            {
              key: 'sps',
              score: true,
            },
            {
              key: 'sc',
              score: true,
            },
          ]}
        />
        <SummaryTooltip/>
      </div>
    </div>
  );
};

const fauxData = [
  {
    id: 1,
    district: 'Sample District',
    schools: 5,
    classrooms: 50,
    students: 5,
    score: 125,
    er: 50,
    spt: 85,
    sps: 102,
    sc: 131,
  },
  {
    id: 2,
    district: 'Sample District',
    schools: 5,
    classrooms: 50,
    students: 5,
    score: 85,
    er: 102,
    spt: 125,
    sps: 80,
    sc: 40,
  },
  {
    id: 3,
    district: 'Sample District',
    schools: 5,
    classrooms: 50,
    students: 5,
    score: 140,
    er: 85,
    spt: 105,
    sps: 115,
    sc: 150,
  },
];

export default SummaryDistrict;
