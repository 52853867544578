import { gql } from '@apollo/client';

const UserQuery = gql`
  query UserQuery($userID: ID) {
    getUser(userID: $userID) {
      id
      first_name
      last_name
      grade
      school
    }
  }
`;

export default UserQuery;
