import PropTypes from 'prop-types';

const fourColorRange = [
  'orange',
  'light-orange',
  'light-green',
  'light-green-2',
];

const fiveColorRange = [
  'orange',
  'light-orange',
  'light-grey',
  'light-green',
  'light-green-2',
];

const ScoredCell = (props) => {
  const {
    score,
    width,
    scoreRanges,
  } = props;

  const colorRange = (scoreRanges.length === 4) ? fourColorRange : fiveColorRange;
  const colorIndex = scoreRanges.findIndex((range) => (score >= range[0] && score < range[1]));
  const color = colorRange[colorIndex];

  return (
    <div className={`scored-cell ${color}`} style={{ width: `${width}%` }}>{score}</div>
  );
};

ScoredCell.propTypes = {
  score: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  scoreRanges: PropTypes.array.isRequired,
};

export default ScoredCell;
