import { gql } from '@apollo/client';

const OrganizationsQuery = gql`
  query OrganizationsQuery($orgIDs: [ID], $assessmentID: ID) {
    getOrganizations(orgIDs: $orgIDs, assessmentID: $assessmentID) {
        id
        name
        studentCount
        childOrgs {
            id
            name
            classes {
                id
                name
                studentCount
                statistics {
                  timepoint
                  completionRate
                }
            }
            statistics {
              completionRate
              category
              timepoint
            }
            studentCount
        }
        statistics {
          completionRate
          category
          timepoint
        }
    }
  }
`;

export default OrganizationsQuery;
