import PropTypes from 'prop-types';

const LoadingPane = (props) => {
  return (
    <div className='loading-pane full-screen-view' style={{
      ...props.style,
      position: 'absolute',
    }}>
      <div className='loader' />
    </div>
  );
};

LoadingPane.propTypes = {
  style: PropTypes.string,
};

export default LoadingPane;
