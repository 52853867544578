import { useRef } from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { ResponsiveBar } from '@nivo/bar';

const sampleData = [
  {
    groupKey: 1,
    groupLabel: 'Gr: K (N=101)',
    time_1: 110,
    time_2: 110,
    time_3: 110,
    breakdown: {
      time_1: {
        '0': 15,
        '1': 24,
        '2': 50,
        '3': 11,
      },
      time_2: {
        '0': 12,
        '1': 25,
        '2': 50,
        '3': 13,
      },
      time_3: {
        '0': 30,
        '1': 26,
        '2': 9,
        '3': 35,
      },
    },
  },
  {
    groupKey: 2,
    groupLabel: 'Gr: 1 (N=101)',
    time_1: 110,
    time_2: 110,
    time_3: 110,
    breakdown: {
      time_1: {
        '0': 15,
        '1': 24,
        '2': 50,
        '3': 11,
      },
      time_2: {
        '0': 0,
        '1': 15,
        '2': 85,
        '3': 0,
      },
      time_3: {
        '0': 30,
        '1': 20,
        '2': 10,
        '3': 40,
      },
    },
  },
  {
    groupKey: 3,
    groupLabel: 'Gr: 2 (N=101)',
    time_1: 110,
    time_2: 110,
    time_3: 110,
    breakdown: {
      time_1: {
        '0': 15,
        '1': 24,
        '2': 50,
        '3': 11,
      },
      time_2: {
        '0': 10,
        '1': 25,
        '2': 45,
        '3': 20,
      },
      time_3: {
        '0': 30,
        '1': 26,
        '2': 0,
        '3': 44,
      },
    },
  },
];

// const sampleKeys = ['time_1', 'time_2', 'time_3']

const GREEN = '#76C7BE';
const LIGHT_GREEN = '#BBE4D1';
const LIGHT_GREY = '#B7B7B7';
const LIGHT_ORANGE = '#FED693';
const ORANGE = '#F5AD52';

const COLORS_BY_COUNT = {
  '4': [
    ORANGE,
    LIGHT_ORANGE,
    LIGHT_GREEN,
    GREEN,
  ],
  '5': [
    GREEN,
    LIGHT_GREEN,
    LIGHT_GREY,
    LIGHT_ORANGE,
    ORANGE,
  ],
};

const BarGroupChart = (props) => {
  const {
    data, enableXLabel, keys, node, topLabel,
  } = props;
  const nodeRef = useRef();

  if (data.length === 0) return <div/>;

  const timepointData = data[0].breakdown[keys[0]];
  const colors = props.colors ?? timepointData ? COLORS_BY_COUNT[timepointData.length] : COLORS_BY_COUNT.reverse();

  const nodeWidth = (node?.current?.clientWidth / 2);
  const pageWidth = window.innerWidth;
  const __width = node ? nodeWidth : pageWidth;

  function calculateSize (sm, med, lg, xs) {
    if (__width < 400) {
      return xs || sm;
    } else if (__width < 600) {
      return sm;
    } else if (__width < 800) {
      return med;
    } else {
      return lg;
    }
  }

  const CustomLabel = (data) => {
    const { x, value } = data;

    return (
    <g>
      <rect
        x={x - 125 / 2}
        y={20}
        width={120}
        height={2}
        fill="#4E5D78"
      />

      <foreignObject
        x={x - calculateSize(120, 120, 120, 50) / 2}
        y={35}
        style={{
          width: calculateSize(120, 120, 120, 50),
          height: 40,
          fontSize: calculateSize(10, 10, 10, 8),
          color: '#8A94A6',
          wordWrap: 'break-word',
          textAlign: 'center',
        }}
      >
        {value}
      </foreignObject>
    </g>
    );
  };

  return (
    <ResponsiveBar
      barComponent={(p) => BarComponent({
        ...p,
        colors,
        node: node || nodeRef,
        keys: keys ?? [],
        calculateSize,
        __width,
      })}
      data={(data && data.length > 0) ? data : sampleData}
      gridYValues={[]}
      groupMode="grouped"
      indexBy="groupLabel"
      innerPadding={5}
      keys={keys}
      margin={{
        top: topLabel ? 80 : (keys.length > 1) ? 20 : 10,
        right: (enableXLabel ? 10 : 20),
        bottom: 110,
        left: (enableXLabel ? 70 : 50),
      }}
      padding={0.1}
      ref={nodeRef}
      axisTop={{
        tickSize: 0,
        renderTick: () => <div />,
        legend: topLabel,
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        renderTick: CustomLabel,
        legendPosition: 'end',
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickValues: [
          0,
          25,
          50,
          75,
          100,
        ],
        legend: enableXLabel ? 'Percent of student responses' : '',
        legendPosition: 'middle',
        legendOffset: -60,
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              width: '10px',
              fill: '#8A94A6',
              fontSize: 12,
              textWrap: 'wrap',
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fontWeight: 'bold',
              fill: '#4E5D78',
            },
          },
        },
        grid: {
          line: {
            stroke: '#D8DFEC',
            strokeWidth: 1.5,
            strokeDasharray: '4 4',
          },
        },
      }}
    />
  );
};

BarGroupChart.propTypes = {
  data: PropTypes.array,
  enableXLabel: PropTypes.bool,
  topLabel: PropTypes.string,
  colors: PropTypes.array,
  keys: PropTypes.array,
  node: PropTypes.object,
};

const BarComponent = (props) => {
  const {
    colors, bar: {
      data, x, width, height,
    }, keys, calculateSize, __width,
  } = props;
  const breakdown = data.data.breakdown[data.id];

  if (!breakdown) return;

  return (
    <g>
      {(keys.length > 1 && data) &&
        <text
          x={x + (width / 2)}
          y={-15} // the important bit!!
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: calculateSize(10, 10, 12),
            fill: '#8A94A6',
          }}
        >
          {(__width < 400) ? `T${data?.id.split('_')[1]}` : _.startCase(data?.id)}
        </text>
      }

      {Object.keys(breakdown).map((key, i) => {
        const value = breakdown[key];
        const heightPct = height / 100;
        const heightPx = value * heightPct;
        const offsetX = (value.length === 3) ? (width - 20) / 2 : (width - 22) / 2; // handle 100%
        let heightY = 0;
        let idx = i;

        while (idx > 0) {
          idx--;

          heightY += (breakdown[idx] * heightPct);
        }

        return (
          <g key={i}>
            <rect
              fill={colors[i]}
              width={width}
              height={heightPx}
              x={x}
              y={heightY}
            />

            {(value > 5) &&
              <text
                x={x + calculateSize(offsetX, offsetX, offsetX, offsetX)}
                y={heightY + (heightPx / 2)}
                textAnchor='center'
                dominantBaseline="central"
                style={{
                  fontSize: calculateSize(10, 10, 12, 9),
                  fill: '#4E5D78',
                }}
              >
                {`${value}%`}
              </text>
            }
          </g>
        );
      })}
    </g>
  );
};

BarComponent.propTypes = {
  bar: PropTypes.object,
  label: PropTypes.string,
  node: PropTypes.object,
  keys: PropTypes.array,
  barData: PropTypes.array,
  colors: PropTypes.array,
  calculateSize: PropTypes.func,
  __width: PropTypes.number,
};

export default BarGroupChart;
