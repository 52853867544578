import PropTypes from 'prop-types';

import QuestionRow from './QuestionRow';

const CompetencyQuestionSet = (props) => {
  const {
    category,
    studentResponses,
  } = props;

  const categoryIcons = {
    'Self Awareness': require('../../../theme/assets/self-awareness-icon.png'),
    'Self Management': require('../../../theme/assets/self-management-icon.png'),
    'Social Awareness': require('../../../theme/assets/social-awareness-icon.png'),
    'Relationship Skills': require('../../../theme/assets/relationship-skills-icon.png'),
    'Responsible Decision Making': require('../../../theme/assets/responsible-decision-making-icon.png'),
    'Growth Mindset': require('../../../theme/assets/growth-mindset-icon.png'),
    Hope: require('../../../theme/assets/hope-icon.png'),
    self_awareness: require('../../../theme/assets/self-awareness-icon.png'),
    self_management: require('../../../theme/assets/self-management-icon.png'),
    social_awareness: require('../../../theme/assets/social-awareness-icon.png'),
    rel_skills: require('../../../theme/assets/relationship-skills-icon.png'),
    resp_dec_making: require('../../../theme/assets/responsible-decision-making-icon.png'),
  };

  const categoryNames = {
    self_awareness: 'Self Awareness',
    self_management: 'Self Management',
    social_awareness: 'Social Awareness',
    rel_skills: 'Relationship Skills',
    resp_dec_making: 'Respectful Decision Making',
  };

  const iconSrc = categoryIcons[category] || null;

  return (
    <div>
      <div className='competency-header'>
        {iconSrc && <img alt='breadcrumb-icon' src={iconSrc} className='competency-icon'/>}
        {categoryNames[category] ? categoryNames[category] : category}
      </div>
      {studentResponses.map((answer, idx) => (
        <div key={idx} className='user-response-row' style={{
          backgroundColor: idx % 2 === 0 ? 'white' : '#F0F5FF',
        }}>
          <QuestionRow
            question={answer.questionText}
            score={answer.answerValue}
          />
        </div>
      ))}
    </div>
  );
};

CompetencyQuestionSet.propTypes = {
  category: PropTypes.string.isRequired,
  studentResponses: PropTypes.array,
};

CompetencyQuestionSet.defaultProps = {
  studentResponses: [],
};

export default CompetencyQuestionSet;
