import PropTypes from 'prop-types';
import _ from 'lodash';
import ScoredCell from './ScoredCell';
import {
  useState, useEffect, useRef,
} from 'react';
import Pager from './Pager';
import FilterBoxes from './FilterBoxes';
import { isClimateSurvey } from '../../utils';

const TableView = (props) => {
  const {
    className,
    assessment,
    data,
    dataMappings,
    headers,
    onClick,
    sortKey,
    sortDirection,
    onSort,
    onFilter,
    gradesArray,
    filterList,
    // stickyNode,
  } = props;

  const width = (1 / headers.length) * 100;
  const scoreRanges = isClimateSurvey(assessment)
    ? [
        [ 1, 2 ],
        [ 2, 3 ],
        [ 3, 4 ],
        [ 4, Infinity ],
      ]
    : ([
        '5',
        '84',
        '85',
      ].includes(assessment.id))
        ? [
            [ 1, 2 ],
            [ 2, 3 ],
            [ 3, 4 ],
            [ 4, 5 ],
            [ 5, Infinity ],
          ]
        : [
            [ 0, 70 ],
            [ 70, 90 ],
            [ 90, 115 ],
            [ 115, Infinity ],
          ];

  const handleSort = (key) => {
    if (onSort) {
      onSort(key);
    }
  };

  const handleFilter = (val) => {
    if (onFilter) {
      onFilter(val);
    }
  };

  const getFilterCount = (key) => {
    const filters = filterList?.filter((filter) => filter.includes(key));
    return filters ? filters.length : 0;
  };

  const earlyElemDomains = [
    'sc',
    'spt',
    'sps',
    'er',
    'overall',
  ];
  const middleSchoolDomains = [
    'sa',
    'sm',
    'so',
    'rs',
    'rd',
    'overall',
  ];
  const lateElemDomains = [
    'uo',
    'sps',
    'sc',
    'sa',
    'overall',
  ];
  const highSchoolDomains = [
    'rel_skills',
    'resp_dec_making',
    'self_awareness',
    'self_management',
    'social_awareness',
  ];
  const highSchool7mDomains = [
    'Self Awareness',
    'Self Management',
    'Social Awareness',
    'Growth Mindset',
    'Hope',
    'Relationship Skills',
    'Responsible Decision Making',
  ];
  const climateDomains = [
    'is1',
    'is2',
    'sb1',
    'sb2',
    'ss1',
    'ss2',
  ];

  const allFilterableDomains = [
    ...earlyElemDomains,
    ...middleSchoolDomains,
    ...lateElemDomains,
    ...highSchoolDomains,
    ...highSchool7mDomains,
    ...climateDomains,
  ];

  /* opening and closing filter list */
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ modalHeader, setModalHeader ] = useState('');
  const filterRef = useRef(null)
;
  const openModal = (header) => {
    setIsModalOpen(true);
    setModalHeader(header);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalHeader('');
  };

  const handleClickOutside = (e) => {
    if (filterRef.current && !filterRef.current.contains(e.target)) closeModal();
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ isModalOpen ]);

  return (
    <div className={`table-view ${className || ''}`}>
      <ul className='table-headers' style={{
        position: 'sticky',
        top: 134,
      }}>
        {headers.map((header, i) => {
          const isFilterableDomain = allFilterableDomains.includes(header.key);
          const isGrade = header.key === 'grade';
          const showFilters = (isFilterableDomain || (isGrade && gradesArray.length > 1));
          const filterCount = getFilterCount(header.key);
          const isSorted = sortKey === header.key;
          const direction = isSorted ? sortDirection : null;
          const sortIcon = !isSorted ? require('../../theme/assets/sort-vector-icon.png') : isSorted && direction === 'asc' ? require('../../theme/assets/sort-vector-icon-only-up.png') : require('../../theme/assets/sort-vector-icon-only-down.png');
          return (
            <li className='table-header' style={{ width: `${width}%` }} key={i}>
              <button type='button' onClick={() => {
                if (showFilters) openModal(header.key);
              }}>
                <div className='cell-label'>
                  <div className='sort-col-label'>{header.label}</div>
                  {showFilters && filterCount > 0 && <div className='col-filter-count'>{`(${filterCount})`}</div>}
                  <img
                  alt='sort-icon'
                  className='sort-icon'
                  src={sortIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSort(header.key);
                  }}
                  />
                </div>
              </button>
              {(isModalOpen && (header.key === modalHeader)) &&
                    <FilterBoxes
                    isGrade={modalHeader === 'grade'}
                    isEarlyElemDomain={earlyElemDomains.includes(modalHeader)}
                    isMiddleSchoolDomain={middleSchoolDomains.includes(modalHeader)}
                    isLateElemDomain={lateElemDomains.includes(modalHeader)}
                    isHighSchoolDomain={highSchoolDomains.includes(modalHeader)}
                    isClimateDomain={climateDomains.includes(modalHeader)}
                    isHighSchool7mDomain={highSchool7mDomains.includes(modalHeader)}
                    filterRef={filterRef}
                    filterList={filterList}
                    header={header}
                    handleFilter={handleFilter}
                    gradesArray={gradesArray}
                    />}
            </li>
          );
        })}
      </ul>

      {data.length === 0
        ? <div className='hint-cell'>There are no results to display.</div>
        : <div>
          <Pager
          items={data}
          scrollToTopOnPageChange={true}
          pageRange={5}
          showJumpToPage={false}
          render={(pagedData) => (
            <ul className='data-list-view scroll'>
              {pagedData.map((obj, i) => {
                const { id } = obj;
                const idx = id || i;
                return (
                  <li className={`table-cell ${onClick ? 'clickable' : ''}`} key={idx} onClick={() => onClick ? onClick(obj) : null}>
                    {dataMappings.map((dataMapping) => {
                      const { key, score } = dataMapping;
                      return score
                        ? (
                        <ScoredCell score={obj[key]} width={width} key={key} scoreRanges={scoreRanges}/>
                          )
                        : (
                        <div className='cell-label ellipsis' style={{ width: `${width}%` }} key={key}>
                          {_.get(obj, key) || _.get(obj, key) === 0 ? _.get(obj, key) : '-'}
                        </div>
                          );
                    })}
                  </li>
                );
              })}
            </ul>
          )}/>
        </div>
      }
    </div>
  );
};

TableView.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })),
  data: PropTypes.array,
  dataMappings: PropTypes.array,
  stickyNode: PropTypes.object,
  onClick: PropTypes.func,
  sortKey: PropTypes.string,
  sortDirection: PropTypes.oneOf([ 'asc', 'desc' ]),
  onSort: PropTypes.func,
  onFilter: PropTypes.func,
  gradesArray: PropTypes.array,
  filterList: PropTypes.array,
  assessment: PropTypes.object,
};

export default TableView;
