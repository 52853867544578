import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import to from 'await-to-js';

import getAuthToken from '../get-auth-token';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_BASE_URL,
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, _operation) => !!error,
  },
  delay: (count) => {
    const randomExpBackOffDelayMs = Math.pow(10, count) + Math.round((Math.random() * count * 1000));
    return randomExpBackOffDelayMs;
  },
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const [ tokenErr, token ] = await to(getAuthToken());
  if (tokenErr) {
    throw tokenErr;
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  link: from([
    authLink,
    retryLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
});

export default client;
