import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';

const Checkbox = (props) => {
  const {
    className,
    checked: value,
    onChange,
    subtitle,
    title,
    type,
  } = props;
  const [ checked, setChecked ] = useState(false);

  useEffect(() => {
    setChecked(value);
  }, [ value ]);

  return (
    <div className={`checkbox-view ${className || ''}`} onClick={() => onChange(!checked)}>
      <div className='checkbox-icon'>
        {(type === 'radio')
          ? <img
              alt='checkbox-icon'
              src={require(`../../theme/assets/${checked ? 'radio-selected-icon' : 'radio-icon'}.png`)}
            />
          : <img
              alt='checkbox-icon'
              src={require(`../../theme/assets/${checked ? 'checkbox-selected-icon' : 'checkbox-icon'}.png`)}
            />
        }
      </div>

      <div className='right-section'>
        <div className='title'>{title}</div>
        {subtitle && <div className='subtitle'>{subtitle}</div>}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

export default Checkbox;
