import PropTypes from 'prop-types';
import _ from 'lodash';

import CompetencyQuestionSet from './CompetencyQuestionSet';
import SummaryTooltip from '../Dashboard/Summary/Tooltip';

const UserResponses = (props) => {
  const {
    selectedAssessment,
    isClimate,
    isHighSchool,
    isHideTooltip,
  } = props;

  const answers = selectedAssessment.answers || [];
  const answersByCategory = _.groupBy(answers, 'category');

  return (
    <div className='user-responses-container'>
      {!isHideTooltip &&
        <div className='user-report-tooltip'>
          <SummaryTooltip
            isClimate={isClimate}
            isHighSchool={isHighSchool}
          />
        </div>
      }
      {answers.length
        ? (
        <>
          <div className='user-response-table-header'>
            <p style={{
              marginLeft: '10px',
            }}>Question</p>
            <p style={{
              marginRight: '125px',
            }}>Your Response</p>
          </div>
          {Object.keys(answersByCategory).map((category, idx) => (
              <CompetencyQuestionSet
                key={idx}
                category={category}
                studentResponses={answersByCategory[category]}
              />
          ))}
        </>
          )
        : (
        <div className='hint-label'>Response to each question is not available for this assessment.</div>
          )}
    </div>
  );
};

UserResponses.propTypes = {
  selectedAssessment: PropTypes.object.isRequired,
  isClimate: PropTypes.bool.isRequired,
  isHighSchool: PropTypes.bool.isRequired,
  isHideTooltip: PropTypes.bool,
};

export default UserResponses;
