import { gql } from '@apollo/client';

const DashboardStudentQuery = gql`
  query DashboardStudentQuery($orgIDs: [ID], $classIDs: [ID], $assessmentID: ID) {
    getUsers(orgIDs: $orgIDs, classIDs: $classIDs, assessmentID: $assessmentID) {
        id
        first_name
        last_name
        classes {
            id
            name
        }
        grade
        scores {
          category
          score
          orgAssessmentID
        }
        school
    }
  }
`;

export default DashboardStudentQuery;
