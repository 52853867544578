import to from 'await-to-js';
import { jwtDecode } from 'jwt-decode';

let savedToken = '';

const TOKEN_EXP_BUFFER_SECONDS = 60;

export const parseTokenData = (token) => {
  let decoded;
  try {
    decoded = jwtDecode(token);
  } catch (ex) {
    console.error(ex.message);
    return true;
  }
  return decoded;
};

const isTokenExpired = (token) => {
  const tokenData = parseTokenData(token);
  return ((tokenData.exp * 1000) < (Date.now() - (TOKEN_EXP_BUFFER_SECONDS * 1000)));
};

const fetchWpToken = async () => {
  const url = `${window.wpApiSettings.root}api/v1/jwt?_wpnonce=${window.wpApiSettings.nonce}`;
  const [ fetchErr, response ] = await to(fetch(url, {
    method: 'GET',
    cache: 'no-cache',
  }));
  if (fetchErr) {
    console.error(fetchErr);
    throw fetchErr;
  }
  if (response.ok) {
    const [ parseErr, body ] = await to(response.json());
    if (parseErr) {
      console.error(parseErr);
      throw parseErr;
    }
    return body.jwt;
  }
  throw new Error(`Unable to fetch token: ${response.statusText}`);
};

const getAuthToken = async () => {
  // first check to see if token is saved in memory
  if (savedToken && !isTokenExpired(savedToken)) {
    return savedToken;
  }
  // then check the URL for a "token" query param
  if (document.location.search) {
    const queryObj = new URLSearchParams(document.location.search);
    const token = queryObj.get('token');
    if (!isTokenExpired(token)) {
      savedToken = token;
      return savedToken;
    }
    console.error('Expired or invalid token detected on the URL');
  }
  // then check to see if we are in a WordPress environment
  if (window.wpApiSettings) {
    const [ err, token ] = await to(fetchWpToken());
    if (err) {
      throw err;
    }
    savedToken = token;
    return savedToken;
  }
  throw new Error('Unable to get auth token');
};

export default getAuthToken;
