export const isClimateSurvey = (assessment) => {
  const assessmentIds = [
    4,
    86,
    95,
  ];

  if (assessment?.id) {
    return assessmentIds.includes(parseInt(assessment?.id));
  } else {
    return false;
  }
};
