import { gql } from '@apollo/client';

const GetXselSchoolsQuery = gql`
  query GetXselSchools {
    getXselSchools {
      id
      schoolId
      school
      districtId
      district
      sevenMindsetsGroup {
        id
        name
        districtId
        districtName
        state
      }
    }
  }
`;

export default GetXselSchoolsQuery;
