import PropTypes from 'prop-types';

const FilterBoxes = ({
  isGrade,
  isEarlyElemDomain,
  isMiddleSchoolDomain,
  isLateElemDomain,
  isHighSchoolDomain,
  isHighSchool7mDomain,
  isClimateDomain,
  filterRef,
  filterList,
  header,
  handleFilter,
  gradesArray,
}) => {
  if (!filterList) filterList = [];
  return (
        <div ref={filterRef} className='cell-label checkbox-list'>
          <p className='filter-by-header'>Filter By:</p>
          {(isEarlyElemDomain || isMiddleSchoolDomain || isLateElemDomain) && (
                  <div className='checkbox-container'>
                    <label className='checkbox-label'>
                      <input
                        className='checkbox-box'
                        type='checkbox'
                        checked={filterList.includes(`${header.key}-aboveAvg`)}
                        value={`${header.key}-aboveAvg`}
                        onChange={(e) => handleFilter(e.target.value)}
                      />
                      Above
                    </label>
                    <label className='checkbox-label'>
                      <input
                        className='checkbox-box'
                        type='checkbox'
                        checked={filterList.includes(`${header.key}-avg`)}
                        value={`${header.key}-avg`}
                        onChange={(e) => handleFilter(e.target.value)}
                      />
                      At / Around
                    </label>
                    <label className='checkbox-label'>
                      <input
                        className='checkbox-box'
                        type='checkbox'
                        checked={filterList.includes(`${header.key}-belowAvg`)}
                        value={`${header.key}-belowAvg`}
                        onChange={(e) => handleFilter(e.target.value)}
                      />
                      Below
                    </label>
                    <label className='checkbox-label'>
                      <input
                        className='checkbox-box'
                        type='checkbox'
                        checked={filterList.includes(`${header.key}-wellBelowAvg`)}
                        value={`${header.key}-wellBelowAvg`}
                        onChange={(e) => handleFilter(e.target.value)}
                      />
                      Well Below
                    </label>
                  </div>
          )}
                {isClimateDomain && (
                  <div className='checkbox-container'>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-agreeALot`)}
                          value={`${header.key}-agreeALot`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Agree a Lot
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-agreeALittle`)}
                          value={`${header.key}-agreeALittle`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Agree a Little
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-disagreeALittle`)}
                          value={`${header.key}-disagreeALittle`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Disagree a Little
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-disagreeALot`)}
                          value={`${header.key}-disagreeALot`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Disagree a Lot
                    </label>
                  </div>
                )}
                {(isHighSchoolDomain || isHighSchool7mDomain) && (
                  <div className='checkbox-container'>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-highlyConfident`)}
                          value={`${header.key}-highlyConfident`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Highly Confident
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-confident`)}
                          value={`${header.key}-confident`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Confident
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-sometimesConfident`)}
                          value={`${header.key}-sometimesConfident`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Sometimes Confident
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-notVeryConfident`)}
                          value={`${header.key}-notVeryConfident`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Not Very Confident
                    </label>
                    <label className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`${header.key}-notConfident`)}
                          value={`${header.key}-notConfident`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        Not Confident
                    </label>
                  </div>
                )}
                {(isGrade && (gradesArray.length > 1)) && (
                  <div className='checkbox-container'>
                    {gradesArray.map((grade) => (
                      <label key={grade} className='checkbox-label'>
                        <input
                          className='checkbox-box'
                          type='checkbox'
                          checked={filterList.includes(`grade-${grade}`)}
                          value={`grade-${grade}`}
                          onChange={(e) => handleFilter(e.target.value)}
                        />
                        {grade}
                      </label>
                    ))}
                  </div>
                )}
        </div>
  );
};

FilterBoxes.propTypes = {
  isGrade: PropTypes.bool,
  isEarlyElemDomain: PropTypes.bool,
  isMiddleSchoolDomain: PropTypes.bool,
  isLateElemDomain: PropTypes.bool,
  isHighSchoolDomain: PropTypes.bool,
  isHighSchool7mDomain: PropTypes.bool,
  isClimateDomain: PropTypes.bool,
  width: PropTypes.number,
  filterRef: PropTypes.object,
  filterList: PropTypes.array,
  header: PropTypes.object,
  handleFilter: PropTypes.func,
  idx: PropTypes.number,
  gradesArray: PropTypes.array,
};

export default FilterBoxes;
