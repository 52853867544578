import { gql } from '@apollo/client';

const DashboardClassroomQuery = gql`
  query DashboardClassroomQuery($orgIDs: [ID], $assessmentID: ID) {
    getOrganizations(orgIDs: $orgIDs, assessmentID: $assessmentID) {
        id
        name
        classes {
          statistics {
            category
          }
        }
        childOrgs {
          id
          name
          statistics {
            category
            average
            timepoint
          }
          classes {
            id
            name
            statistics {
              category
              average
              timepoint
            }
            studentCount
          }
        }
    }
  }
`;

export default DashboardClassroomQuery;
