import {
  createHashRouter,
  redirect,
} from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import UserReport from './pages/UserReport';
import UserReportPdf from './pages/UserReport/pdf';
import Admin from './pages/Admin';

const router = createHashRouter([
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/users/:user/assessment/:assessment/schoolYear/:schoolYear/timepoint/:timepoint',
    element: <UserReport/>,
  },
  {
    path: '/users/:user/assessment/:assessment/schoolYear/:schoolYear/timepoint/:timepoint/pdf',
    element: <UserReportPdf/>,
  },
  {
    path: '/admin',
    element: <Admin />,
  },
  {
    path: '/',
    loader: () => redirect('/dashboard'),
  },
  {
    path: '/index.html',
    loader: () => redirect('/dashboard'),
  },
]);

export default router;
