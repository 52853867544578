import PropTypes from 'prop-types';
import {
  useEffect, useRef, useState,
} from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { isClimateSurvey } from '../../../../utils';

import BarChart from '../../../BarChart';
import BarGroupChart from '../../../BarGroupChart';
import Checkbox from '../../../Checkbox';
import LoadingPane from '../../../LoadingPane';
import SummaryTooltip from './Tooltip';

import ORGANIZATION_ASSESSMENTS_QUERY from '../../../../graphql/queries/organization/assessments';
import ORGANIZATIONS_GRADE_SCORES_QUERY from '../../../../graphql/queries/components/pages/Dashboard/Plots';
import ORGANIZATIONS_SCORES_QUERY from '../../../../graphql/queries/components/pages/Dashboard/School';

const generateBarData = (assessments, times, statistics, categories) => {
  if (!statistics || statistics.length === 0) return [];

  const statisticsByTimes = statistics.filter((stat) => times.includes(stat.timepoint));

  if (times.length === 0) return [];
  else if (times.length === 1) {
    return _.compact(statisticsByTimes.map((s) => {
      if (!s.distribution) return null;
      const category = (categories || []).find((c) => c.key === s.category);
      const barData = {
        label: category?.name ?? s.category,
      };

      s.distribution.forEach((score, i) => {
        barData[i] = score;
      });

      return barData;
    }));
  } else {
    // multiple timepoints selected
    const hash = {};

    statisticsByTimes.forEach((stat) => {
      const {
        category, distribution,
      } = stat;
      if (distribution) {
        if (hash[category]) {
          hash[category].push(distribution);
        } else {
          hash[category] = [ distribution ];
        }
      }
    });

    const groupedData = Object.keys(hash).map((key, i) => {
      const category = (categories || []).find((c) => c.key === key);
      const distributions = hash[key];
      const barData = {
        groupKey: i + 1,
        groupLabel: category?.name,
        breakdown: {},
      };

      // map the timepoints
      times.forEach((t, i) => {
        barData[`time_${i + 1}`] = 110;
      });

      // map the distributions per category
      distributions.forEach((distribution, i) => {
        barData.breakdown[`time_${i + 1}`] = distribution.slice(0).reverse();
      });

      return barData;
    });

    return groupedData;
  }
};

const SummaryPlots = (props) => {
  const {
    assessment,
    district,
    school,
    classroom,
    schools,
    setParentTimepoints,
    setInSummaryPlot,
  } = props;
  const [ statistics, setStatistics ] = useState([]);
  const [ times, setTimes ] = useState([]);
  const [ assessments, setAssessments ] = useState([]);

  const {
    data: response, error, loading, refetch: refetchScores,
  } = useQuery(ORGANIZATIONS_SCORES_QUERY, {
    variables: {
      orgIDs: (school) ? [ school.id ] : schools.map((org) => org.id),
      assessmentID: assessment?.id,
    },
  });
  const {
    data: orgAssessments, error: error2, loading: loading2, /* refetch: refetchOrgAssessments, */
  } = useQuery(ORGANIZATION_ASSESSMENTS_QUERY, {
    variables: {
      orgIDs: school ? [ school.id ] : schools.map((org) => org.id),
    },
  });

  useEffect(() => {
    if (!loading && !loading2) {
      if (error) {
        window.alert(error);
      } else if (error2) {
        window.alert(error2);
      } else if (response?.getOrganizations) {
        const __district = response?.getOrganizations[0]; // district
        const __orgAssessments = orgAssessments.getOrgAssessments.filter((o) => o.assessment_id === assessment?.id);

        setupStatistics(__district);
        setAssessments(__orgAssessments);

        if (__orgAssessments.length > 0) {
          const tempTimes = [ __orgAssessments[0].timepoint ];
          setTimes(tempTimes); // preset selection to first assessment
          setInSummaryPlot(true);
          setParentTimepoints(tempTimes);
        }
      }
    }
  }, [
    error,
    loading,
    loading2,
    district,
    school,
    classroom,
  ]);

  useEffect(() => {
    refetchScores({
      orgIDs: (school) ? [ school.id ] : schools.map((org) => org.id),
      assessmentID: assessment?.id,
    });
  }, [ assessment ]);

  function setupStatistics (district) {
    if (school) {
      const org = district.childOrgs.find((o) => o.id === school.id);
      setStatistics(org?.statistics);
    } else if (classroom) {
      const org = district.childOrgs.find((o) => o.id === school.id);
      if (!org) return;
      const classroom = org.classes.find((c) => c.id === classroom.id);
      if (!classroom) return;

      setStatistics(classroom.statistics);
    } else {
      setStatistics(district.statistics);
    }
  }

  function generateTimepoints () {
    const arr = [];
    const max = Math.max(...assessments.map((a) => a.timepoint));
    let idx = 1;

    while (idx <= max) {
      arr.push(idx);
      idx++;
    }
    return arr;
  }

  function handleTime (time) {
    const existing = times.includes(time);

    if (existing) {
      // remove
      const i = times.findIndex((t) => t === time);
      const tempTimes = [ ...times.slice(0, i), ...times.slice(i + 1, times.length) ];

      setTimes(tempTimes);
      setInSummaryPlot(true);
      setParentTimepoints(tempTimes);
    } else {
      setTimes([ ...times, time ]);
      setInSummaryPlot(true);
      setParentTimepoints([ ...times, time ]);
    }
  }

  if (loading) return <LoadingPane />;

  const categories = assessment.categories.split(',').map((category) => {
    const strParts = category.split('|');
    return {
      key: strParts[0],
      name: strParts[1] ?? strParts[0],
    };
  });
  const barData = generateBarData(assessments, times, statistics, categories);

  return (
    <div className='summary-plots-view'>
      <ul className='times-frame'>
        {generateTimepoints().map((timepoint) => {
          const selected = times.includes(timepoint);

          return (
            <Checkbox
              key={timepoint}
              onChange={() => handleTime(timepoint)}
              checked={selected}
              title={`Time ${timepoint}`}
            />
          );
        })}
      </ul>

      <div className='graphs-frame'>
        {barData.length === 0
          ? <div className='empty-graph-frame'>
            <div className='hint-label'>There is no graphical data to display.</div>
          </div>
          : ((times.length > 1)
              ? <BarGroupChart
            keys={times.map((t) => `time_${t}`).sort()}
            data={barData}
          />
              : <BarChart
            keys={Object.keys(barData[0]).filter((key) => key !== 'label')}
            data={barData}
          />)
        }

        <SummaryTooltip
          isClimate={isClimateSurvey(assessment)}
          isHighSchool={[
            '5',
            '84',
            '85',
          ].includes(assessment.id)}
        />

        <GradeLevelSummaryPlots
          assessment={assessment}
          assessments={assessments}
          classroom={classroom}
          schools={schools}
          school={school}
          times={times}
        />
      </div>
    </div>
  );
};

SummaryPlots.propTypes = {
  assessment: PropTypes.object,
  district: PropTypes.object,
  school: PropTypes.object,
  classroom: PropTypes.object,
  hasDistrict: PropTypes.bool,
  schools: PropTypes.array,
  setParentTimepoints: PropTypes.func,
  setInSummaryPlot: PropTypes.func,
};

// ----summary plots by grade or category---- //
const GradeLevelSummaryPlots = ({
  assessment,
  assessments,
  classroom,
  schools,
  school,
  times,
}) => {
  const {
    data,
    // error,
    loading,
    refetch,
  } = useQuery(ORGANIZATIONS_GRADE_SCORES_QUERY, {
    variables: {
      orgIDs: (school) ? [ school.id ] : schools.map((org) => org.id),
      assessmentID: assessment?.id,
    },
  });

  useEffect(() => {
    refetch({
      orgIDs: (school) ? [ school.id ] : schools.map((org) => org.id),
      assessmentID: assessment?.id,
    });
  }, [
    school,
    schools,
    assessment,
  ]);

  const nodeRef = useRef();
  const categories = assessment.categories.split(',').map((category) => {
    const strParts = category.split('|');
    return {
      key: strParts[0],
      name: strParts[1] ?? strParts[0],
    };
  });

  if (loading) return <LoadingPane />;

  const isClimate = isClimateSurvey(assessment);

  if (!data?.getGradeStatistics?.length) {
    return (
      <div className='section-frame'>
        <div className='section-header'>{`Summary Plots By ${isClimate ? 'Category' : 'Grade'}`}</div>
        <div className='hint-label'>{`There are no ${isClimate ? 'categories' : 'grade statistics'} to display.`}</div>
      </div>
    );
  }

  if (!classroom) {
    return (
      <div className='section-frame'>
        <div className='section-header'>{`Summary Plots By ${isClimate ? 'Category' : 'Grade'}`}</div>

        <div className='subgraphs-frame'>
          {isClimate
            ? categories.map((category, i) => {
              const groupStats = data.getGradeStatistics.map((stat) => {
                const statistic = stat.statistics.find((s) => s.category === category.key);

                return {
                  ...stat,
                  statistic,
                  hasStat: !!statistic,
                };
              });
              const barGroupData = groupStats.filter((g) => g.statistics.length > 0).map((groupStat, i) => {
                return {
                  groupKey: i,
                  groupLabel: (groupStats.length > 5) ? `Gr: ${groupStat.grade}` : `Gr: ${groupStat.grade} (N=${groupStat.studentCount})`,
                  time_1: 110,
                  breakdown: {
                    time_1: groupStat?.statistic ? groupStat.statistic.distribution.slice(0) : null,
                  },
                };
              });

              return (
                <div className='subgraph-frame' key={category.name} style={{ height: 500 }}>
                  <BarGroupChart
                    keys={[ 'time_1' ]}
                    data={barGroupData}
                    topLabel={category.name}
                    enableXLabel={i % 2 === 0}
                  />
                </div>
              );
            })
            : data.getGradeStatistics.map((stat) => {
              if (!stat.statistics || !stat.grade || stat.grade === 'null') {
                return null;
              }
              const gradeBarData = generateBarData(assessments, times, stat.statistics, categories, true);

              return (
                <div className='subgraph-frame' key={stat.grade}>
                  <div className='subgraph-header-frame'>
                    <div className='grade-frame'>
                      <div className='grade-level'>
                        <div className='grade-header'>Grade</div>
                        <div className='grade'>{stat.grade}</div>
                      </div>
                    </div>

                    <div className='students-frame'>
                      <div className='grade-level'>
                        <div className='grade-header'>Number of Students</div>
                        <div className='grade'>{stat.studentCount}</div>
                      </div>
                    </div>
                  </div>

                  {(gradeBarData.length > 0) && (
                    <div className='graph' ref={nodeRef}>
                      {(times.length > 1)
                        ? <BarGroupChart
                          keys={times.map((t) => `time_${t}`).sort()}
                          data={gradeBarData}
                          node={nodeRef}
                        />
                        : <BarChart
                          keys={Object.keys(gradeBarData[0]).filter((key) => key !== 'label')}
                          data={gradeBarData}
                          node={nodeRef}
                        />
                      }
                    </div>
                  )}
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
};

GradeLevelSummaryPlots.propTypes = {
  assessment: PropTypes.object,
  assessments: PropTypes.array,
  classroom: PropTypes.object,
  school: PropTypes.object,
  schools: PropTypes.array,
  times: PropTypes.array,
};

export default SummaryPlots;
