import { gql } from '@apollo/client';

const DashboardPlotsQuery = gql`
  query DashboardPlotsQuery($orgIDs: [ID]!, $assessmentID: ID) {
    getGradeStatistics(orgIDs: $orgIDs, assessmentID: $assessmentID) {
      grade
      studentCount
      statistics {
        category
        average
        distribution
        timepoint
      }
    }
  }
`;

export default DashboardPlotsQuery;
