import { gql } from '@apollo/client';

const DashboardSchoolQuery = gql`
  query DashboardSchoolQuery($orgIDs: [ID], $assessmentID: ID) {
    getOrganizations(orgIDs: $orgIDs, assessmentID: $assessmentID) {
      id
      name
      statistics {
        category
        average
        distribution
        timepoint
      }
      childOrgs {
        id
        name
        statistics {
          category
          average
          distribution
          timepoint
        }
        classes {
          id
          name
          statistics {
            category
            average
          }
          studentCount
        }
        studentCount
      }
    }
  }
`;

export default DashboardSchoolQuery;
