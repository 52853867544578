import PropTypes from 'prop-types';

const SearchField = (props) => {
  const {
    onChange,
    placeholder,
    value,
  } = props;

  return (
    <div className='search-field-view'>
    <div className='menu-header'>Search</div>

    <input
      className='search-input'
      onChange={onChange}
      placeholder={placeholder}
      value={value || ''}
    />
    </div>
  );
};

SearchField.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
};

export default SearchField;
