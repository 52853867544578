import PropTypes from 'prop-types';

const QuestionRow = (props) => {
  const {
    question,
    score,
  } = props;

  const scoreLanguage = {
    '1': 'Not Confident',
    '2': 'Not Very Confident',
    '3': 'Sometimes Confident',
    '4': 'Confident',
    '5': 'Highly Confident',
  };

  return (
    <>
      <div className='question-container'>
        {question}
      </div>
      <div className='score-container'>
        {scoreLanguage[score]}
        <div className={`score-bubble left-bubble ${score === 1 && 'one-match'}`}></div>
        <div className={`score-bubble ${score === 2 && 'two-match'}`}></div>
        <div className={`score-bubble ${score === 3 && 'three-match'}`}></div>
        <div className={`score-bubble ${score === 4 && 'four-match'}`}></div>
        <div className={`score-bubble right-bubble ${score === 5 && 'five-match'}`}></div>
      </div>
    </>
  );
};

QuestionRow.propTypes = {
  question: PropTypes.string,
  score: PropTypes.number,
};

QuestionRow.defaultProps = {
  question: '',
  score: 0,
};

export default QuestionRow;
