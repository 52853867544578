import { gql } from '@apollo/client';

const AssessmentsQuery = gql`
  query AssessmentsQuery($orgIDs: [ID]!) {
    getAssessmentsByOrgIds(orgIDs: $orgIDs) {
        id
        name
        categories
    }
  }
`;

export default AssessmentsQuery;
