import {
  useState, useCallback, useEffect,
} from 'react';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { isClimateSurvey } from '../../utils';

import USER_ASSESSMENTS_QUERY from '../../graphql/queries/components/pages/UserReport/UserResponses';
import USER_QUERY from '../../graphql/queries/components/pages/UserReport/User';

import TabsView from '../../components/TabsView';
import UserDomains from '../../components/pages/UserReport/UserDomains';
import UserResponses from '../../components/pages/UserReport/UserResponses';
// import Checkbox from '../../components/Checkbox';
import SelectMenu from '../../components/SelectMenu';

const UserReport = () => {
  const navigate = useNavigate();
  const {
    user,
    assessment,
    schoolYear,
    timepoint,
  } = useParams();
  const assessmentNames = {
    '1': 'Early Elementary',
    '2': 'Middle School',
    '3': 'Late Elementary',
    '4': 'Climate',
    '5': 'High School',
    '84': 'High School',
    '85': 'High School Plus',
    '86': 'High School Climate',
  };

  const [ onload, setOnload ] = useState(true);
  const [ userInfo, setUserInfo ] = useState({});
  const [ userAssessments, setUserAssessments ] = useState([]);
  const [ isHighSchool, setIsHighSchool ] = useState(false);
  const [ isClimate, setIsClimate ] = useState(false);
  const [ isEELEMS, setIsEELEMS ] = useState(false);
  const [ matchingUserAssessments, setMatchingUserAssessments ] = useState([]);
  const [ yearsArray, setYearsArray ] = useState([]);
  const [ assessmentsArray, setAssessmentsArray ] = useState([]);
  const [ selectedAssessment, setSelectedAssessment ] = useState({});
  const [ timepoints, setTimepoints ] = useState([]);
  const [ dates, setDates ] = useState({});
  const [ tab, setTab ] = useState(0);

  const {
    data: userData, loading: userLoading, refetch: userRefetch,
  } = useQuery(USER_QUERY, {
    variables: { userID: user },
  });

  const {
    data: assessmentsData, loading: assessmentsLoading, refetch: assessmentsRefetch,
  } = useQuery(USER_ASSESSMENTS_QUERY, {
    variables: { userID: user },
  });

  const fetchUserInfo = useCallback(async () => {
    const response = await userRefetch();

    if (response.error) window.alert(response.error);

    setUserInfo(response.data.getUser || {});
  }, [ user, userRefetch ]);

  const fetchUserAssessments = useCallback(async () => {
    const response = await assessmentsRefetch();

    if (response.error) window.alert(response.error);

    setUserAssessments(response.data.getUserAssessments || []);
  }, [ user, assessmentsRefetch ]);

  useEffect(() => {
    if (onload && !userLoading && !assessmentsLoading) {
      if (userData) setUserInfo(userData.getUser || {});
      if (assessmentsData) setUserAssessments(assessmentsData.getUserAssessments || []);

      setOnload(false);
    }
  }, [
    onload,
    userLoading,
    assessmentsLoading,
    userData,
    assessmentsData,
  ]);

  useEffect(() => {
    if (!onload && user) {
      fetchUserInfo();
      fetchUserAssessments();
    }
  }, [
    user,
    fetchUserInfo,
    fetchUserAssessments,
    onload,
  ]);

  // set up initial selection and filtering
  useEffect(() => {
    if (!onload && userInfo && userAssessments.length > 0) {
      const userAssessmentsMatchingGlobal = userAssessments.filter((userAssessment) => {
        return userAssessment.assessmentID === parseInt(assessment);
      });
      const userAssessmentMatchingUrl = userAssessmentsMatchingGlobal.filter((userAssessment) => {
        return userAssessment.schoolYear === parseInt(schoolYear) && userAssessment.timepoint === parseInt(timepoint);
      });
      const uniqueAssessments = _.sortBy([ ...new Set(userAssessments.map((userAssessment) => userAssessment.assessmentID)) ], (assessmentID) => parseInt(assessmentID));
      const endDateHash = _.groupBy(userAssessmentsMatchingGlobal, 'timepoint');
      const uniqueYears = _.sortBy([ ...new Set(userAssessmentsMatchingGlobal.map((userAssessment) => userAssessment.schoolYear)) ], (schoolYear) => parseInt(schoolYear));
      const uniqueTimepoints = _.sortBy([ ...new Set(userAssessmentsMatchingGlobal.filter((userAssessment) => userAssessment.schoolYear === parseInt(schoolYear)).map((userAssessment) => userAssessment.timepoint)) ], (timepointOption) => parseInt(timepointOption));

      setMatchingUserAssessments(userAssessmentsMatchingGlobal);
      setSelectedAssessment(userAssessmentMatchingUrl[0] || {});
      setYearsArray(uniqueYears);
      setAssessmentsArray(uniqueAssessments);
      setTimepoints(uniqueTimepoints);
      setIsHighSchool([
        '5',
        '84',
        '85',
      ].includes(assessment));
      setIsClimate(isClimateSurvey(assessment));
      setIsEELEMS([
        '1',
        '2',
        '3',
      ].includes(assessment));
      setDates(endDateHash);
    }
  }, [
    userInfo,
    userAssessments,
    assessment,
    schoolYear,
    timepoint,
    onload,
  ]);

  const handleAssessmentChange = (newAssessment) => {
    navigate(`/users/${user}/assessment/${newAssessment}/schoolYear/${schoolYear}/timepoint/${timepoint}`);
  };

  const handleTimepointChange = (newTimepoint) => {
    navigate(`/users/${user}/assessment/${assessment}/schoolYear/${schoolYear}/timepoint/${newTimepoint}`);
  };

  const handleSchoolYearChange = (newSchoolYear) => {
    navigate(`/users/${user}/assessment/${assessment}/schoolYear/${newSchoolYear}/timepoint/${timepoint}`);
  };

  const generateTabs = () => {
    const tabs = [
      {
        name: 'score by competency',
        component: <UserDomains selectedAssessment={selectedAssessment} isClimate={isClimate} isHighSchool={isHighSchool} isEELEMS={isEELEMS} />,
      },
    ];

    if (isHighSchool) {
      tabs.push({
        name: 'response to each question',
        component: <UserResponses selectedAssessment={selectedAssessment} isClimate={isClimate} isHighSchool={isHighSchool} />,
      });
    };

    return tabs;
  };

  const tabs = generateTabs();

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const userFriendlyDate = date.toLocaleDateString(undefined, options);
    return userFriendlyDate;
  };

  const formatOptions = (arr, type, hint) => {
    const options = _.sortBy(arr.map((num) => ({
      value: num.toString(),
      name: (type === 'assessment') ? assessmentNames[num.toString()] : (type === 'timepoint') ? `${num.toString()}: ${formatDate(dates[num][0].endDate)}` : num.toString(),
    })), 'value');
    return (hint) ? [ hint, ...options ] : options;
  };

  const assessmentName = assessmentNames[assessment] ?? '';

  return (
      <div className='user-report-view'>
        <div className='toolbar'>
          <button className='back-button' onClick={() => navigate('/dashboard')}>Back</button>

          <div className='toolbar-header'>
            <div className='header'>{`${assessmentName} Assessment`}</div>
            <div className='subheader'>{`${userInfo.first_name} ${userInfo.last_name}`}</div>
          </div>

          <button className='print-button' onClick={() => navigate('pdf')}>View PDF</button>
        </div>
        <div className='dashboard-view' style={{ width: '100%' }}>
          <div className='content-frame' style={{ padding: '0' }}>
            <div className='top-section' style={{ width: '100%' }}>
              <div className='centered-frame'>
                <div className='selection-menus-frame' style={{ width: '100%' }}>
                  <SelectMenu
                    className='selection-menu'
                    header='Assessment'
                    onChange={handleAssessmentChange}
                    options={formatOptions(assessmentsArray, 'assessment')}
                    value={assessment}
                  />
                  <SelectMenu
                    className='selection-menu'
                    header='School Year'
                    // TODO: once schoolYear is in the url hook this up
                    onChange={handleSchoolYearChange}
                    options={formatOptions(yearsArray, 'schoolYear')}
                    value={schoolYear.toString()}
                  />
                  <SelectMenu
                    className='selection-menu'
                    header='Attempt'
                    onChange={handleTimepointChange}
                    options={formatOptions(timepoints, 'timepoint')}
                    value={timepoint.toString()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {matchingUserAssessments.length && <TabsView
            onChange={(t) => setTab(t)}
            tabs={tabs}
            tab={tab}
            setTab={setTab}
          />}
        </div>
      </div>
  );
};

export default UserReport;
