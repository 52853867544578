import { gql } from '@apollo/client';

const UserReportUserResponsesQuery = gql`
  query UserReportUserResponsesQuery($userID: ID!) {
    getUserAssessments(userID: $userID) {
      id
      orgAssessmentID
      timepoint
      schoolYear
      assessmentID
      startDate
      endDate
      scores {
        category
        score
      }
      answers {
        questionId
        questionText
        answer
        answerValue
        category
        userAssessmentID
      }
    }
  }
`;

export default UserReportUserResponsesQuery;
