import { gql } from '@apollo/client';

const OrganizationAssessmentsQuery = gql`
  query OrganizationAssessmentsQuery($orgIDs: [ID]) {
    getOrgAssessments(orgIDs: $orgIDs) {
        id
        name
        timepoint
        assessment_id
        school_year
    }
  }
`;

export default OrganizationAssessmentsQuery;
