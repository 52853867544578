import {
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';

import GET_XSEL_SCHOOLS_QUERY from '../../graphql/queries/xselSchools';
import SAVE_XSEL_SCHOOL_MAPPING from '../../graphql/mutations/saveXselSchoolMapping';

import LoadingPane from '../../components/LoadingPane';

const Admin = () => {
  const {
    data,
    error,
    loading,
    refetch,
  } = useQuery(GET_XSEL_SCHOOLS_QUERY);

  const [ saveMapping, { error: saveError } ] = useMutation(SAVE_XSEL_SCHOOL_MAPPING);
  const navigate = useNavigate();

  const [ idBeingSaved, setIdBeingSaved ] = useState();
  const [ idThatErrored, setIdThatErrored ] = useState();

  if (loading) return <LoadingPane />;
  if (error) {
    return <div className='admin-view'>
      <div style={{
        color: 'red',
        marginBottom: 10,
      }}>
        This page is unavailable:&nbsp;
        {error?.graphQLErrors?.map(({ message }, i) => (
          <span key={'gql' + i}>{message}</span>
        ))}
        {error?.networkError?.message}
      </div>
      <div>
        <a tabIndex='1' style={{
          color: 'blue',
          fontWeight: 'bold',
          cursor: 'pointer',
        }} onClick={() => navigate('/')}>Click here</a> to return to the Insights dashboard
      </div>
    </div>;
  }

  if (!data.getXselSchools.length) {
    return (
      <div>There are no Xsel schools available.</div>
    );
  }
  const sortedSchools = sortBy(sortBy(data.getXselSchools, 'school'), 'district');
  return (
    <div className='admin-view'>
      <h1>Configure Mapping for xSEL Schools to 7 Mindsets Schools</h1>
      <table>
        <thead>
          <tr>
            <th>xSEL School</th>
            <th>xSEL District</th>
            <th></th>
            <th>7M Group ID</th>
            <th>7M Group</th>
            <th>7M District</th>
            <th>7M State</th>
          </tr>
        </thead>
        <tbody>
          {sortedSchools.map((school) => {
            return (
              <tr key={`row|${school.schoolId}`}>
                <td>{school.school}</td>
                <td>{school.district}</td>
                <td>-&gt;</td>
                <td>
                  <input
                    type="number"
                    id={school.schoolId}
                    name="Group ID"
                    defaultValue={school?.sevenMindsetsGroup?.id * 1 || ''}
                    onBlur={(e) => {
                      e.preventDefault();
                      console.log(e.target.id, e.target.value);
                      if (!e.target.value) {
                        return;
                      }
                      setIdBeingSaved(school.schoolId);
                      setIdThatErrored();
                      saveMapping({
                        variables: {
                          externalXselId: e.target.id,
                          external7mId: e.target.value,
                        },
                        onCompleted: () => {
                          setIdBeingSaved();
                          refetch();
                        },
                        onError: () => {
                          setIdBeingSaved();
                          setIdThatErrored(school.schoolId);
                        },
                      });
                    }}
                  />
                </td>
                <td>
                  {(idBeingSaved === school.schoolId) && (
                    <div className='loading-pane'><div className='loader' /></div>
                  )}
                  {(!idBeingSaved && idThatErrored === school.schoolId) && (
                    <div style={{ color: 'red' }}>
                      There was a problem saving this ID:&nbsp;
                      {saveError?.graphQLErrors?.map(({ message }, i) => (
                        <span key={'gql' + i}>{message}</span>
                      ))}
                      {saveError?.networkError?.message}
                    </div>
                  )}
                  {(idBeingSaved !== school.schoolId && school?.sevenMindsetsGroup?.name) && (
                    <>{school?.sevenMindsetsGroup?.name}</>
                  )}
                  {(!idThatErrored && idBeingSaved !== school.schoolId && !school?.sevenMindsetsGroup?.name && school?.sevenMindsetsGroup?.id) && (
                    <div style={{ color: 'red' }}>No 7M group found with this ID!</div>
                  )}
                </td>
                <td>{school?.sevenMindsetsGroup?.districtName}</td>
                <td>{school?.sevenMindsetsGroup?.state}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
