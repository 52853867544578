import {
  useCallback, useEffect, useState,
} from 'react';
import { CSVLink } from 'react-csv';

import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isClimateSurvey } from '../../../../utils';

import ORGANIZATION_ASSESSMENTS_QUERY from '../../../../graphql/queries/organization/assessments';
import ORGANIZATIONS_SCORES_QUERY from '../../../../graphql/queries/components/pages/Dashboard/School';

import Checkbox from '../../../Checkbox';
import LoadingPane from '../../../LoadingPane';
import SearchField from '../../../SearchField';
import TableView from '../../../TableView';

import SummaryTooltip from './Tooltip';

// TODO: see Classroom.jsx for refactor for fewer rerenders

const SummarySchool = (props) => {
  const {
    assessment,
    district,
    stickyNode,
    school,
    schoolsList,
    setParentTimepoint,
    setInSummaryPlot,
  } = props;
  const [ onload, setOnload ] = useState(true);
  const [ time, setTime ] = useState();
  const [ search, setSearch ] = useState();
  const [ schools, setSchools ] = useState([]);
  const [ assessments, setAssessments ] = useState([]);
  const [ categories, setCategories ] = useState([]);
  const [ csvData, setCsvData ] = useState([]);
  const [ sortKey, setSortKey ] = useState('name');
  const [ sortDirection, setSortDirection ] = useState('asc');
  const [ filterList, setFilterList ] = useState([]);
  const [ schoolBaseList, setSchoolBaseList ] = useState([]);
  const {
    data: data2, loading: loading2,
  } = useQuery(ORGANIZATION_ASSESSMENTS_QUERY, { variables: { orgIDs: schoolsList.map((org) => org.id) } });
  const {
    error, loading, refetch,
  } = useQuery(ORGANIZATIONS_SCORES_QUERY, {
    variables: {
      orgIDs: schoolsList.map((org) => org.id),
      assessmentID: assessment?.id,
    },
  });

  const fetchScores = useCallback(async () => {
    const assessments = data2?.getOrgAssessments;
    const response = await refetch();

    function filterByAssessmentId (org, time) {
      const scores = org.statistics ? org.statistics.filter((stat) => stat.timepoint === time) : [];

      return (scores.length > 0) ? org : null;
    }

    if (assessments) {
      const org = response?.data?.getOrganizations[0];
      const schoolsWithScores = org?.childOrgs.filter((o) => filterByAssessmentId(o, time)).map((o) => {
        const __org = { ...o };
        o.statistics.filter((s) => s.timepoint === time).forEach((stat) => {
          __org[stat.category] = stat.average ? Number.isInteger(stat.average) ? stat.average : stat.average.toFixed(2) : '-';
        });

        return __org;
      });
      const csvHeaders = [
        'School',
        'Students',
        ...categories.map((c) => c.name),
      ];
      const csvSchools = schoolsWithScores.map((s) => {
        const scores = [];

        categories.forEach((c) => {
          if (s[c.key]) {
            scores.push(s[c.key]);
          } else {
            scores.push(0);
          }
        });

        return [
          s.name,
          s.studentCount,
          ...scores,
        ];
      });

      setCsvData([ csvHeaders, ...csvSchools ]);
      setSchools(_.sortBy(schoolsWithScores, 'name'));
      setSchoolBaseList(_.sortBy(schoolsWithScores, 'name'));
      setOnload(false);
      setAssessments(assessments);
      if (!time) {
        const tempTime = (assessments.length > 0) ? assessments[0].timepoint : null;
        setTime(tempTime);
        setInSummaryPlot(false);
        setParentTimepoint(tempTime);
      }
    }
  });

  // onload
  useEffect(() => {
    if (error) window.alert(error);
    if (onload && !loading && !loading2) fetchScores();
  }, [
    onload,
    error,
    loading,
    loading2,
    fetchScores,
  ]);

  useEffect(() => {
    // refetch when district or timepoint changes
    setOnload(true);
    setSearch('');
    setSortKey('name');
    setSortDirection('asc');
    setFilterList([]);
  }, [
    district,
    time,
    school,
  ]);

  function generateTimepoints () {
    const arr = [];
    const max = Math.max(...assessments.map((a) => a.timepoint));
    let idx = 1;
    while (idx <= max) {
      arr.push(idx);
      idx++;
    }
    return arr;
  }

  // handles when assessment is changed
  useEffect(() => {
    fetchScores();
    setSearch('');
    setSortKey('name');
    setSortDirection('asc');
    setFilterList([]);

    if (assessment?.categories) {
      setCategories(assessment.categories.split(',').map((category) => {
        const strParts = category.split('|');

        return {
          key: strParts[0],
          name: strParts[1] ?? strParts[0],
        };
      }));
    }
  }, [ assessment ]);

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortDirection('asc');
    }
  };

  // toggling sort column
  useEffect(() => {
    const sortedSchoolsData = _.orderBy(schools, sortKey, sortDirection);
    setSchools(sortedSchoolsData);
  }, [ sortKey, sortDirection ]);

  const handleCheckboxChange = (value) => {
    if (filterList.includes(value)) {
      setFilterList(filterList.filter((filter) => filter !== value));
    } else {
      setFilterList([ ...filterList, value ]);
    }
  };

  // toggling filter keys
  useEffect(() => {
    const isElem = [
      '1',
      '2',
      '3',
    ].includes(assessment.id);
    const isClimate = isClimateSurvey(assessment);
    const isHighSchool = [
      '5',
      '84',
      '85',
    ].includes(assessment.id);
    const scoreBandsHashElem = {
      aboveAvg: {
        min: 115,
        max: Infinity,
      },
      avg: {
        min: 90,
        max: 115,
      },
      belowAvg: {
        min: 70,
        max: 90,
      },
      wellBelowAvg: {
        min: 0,
        max: 70,
      },
    };
    const scoreBandsHashClimate = {
      agreeALot: {
        min: 4,
        max: Infinity,
      },
      agreeALittle: {
        min: 3,
        max: 4,
      },
      disagreeALittle: {
        min: 2,
        max: 3,
      },
      disagreeALot: {
        min: 1,
        max: 2,
      },
    };
    const scoreBandHashHighSchool = {
      highlyConfident: {
        min: 5,
        max: Infinity,
      },
      confident: {
        min: 4,
        max: 5,
      },
      sometimesConfident: {
        min: 3,
        max: 4,
      },
      notVeryConfident: {
        min: 2,
        max: 3,
      },
      notConfident: {
        min: 1,
        max: 2,
      },
    };
    const groupingsFilterHash = {};
    filterList.forEach((filter) => {
      const filterSplit = filter.split('-');
      const grouping = filterSplit[0];
      const scoreBand = filterSplit[1];
      if (!groupingsFilterHash[grouping]) {
        groupingsFilterHash[grouping] = [ scoreBand ];
      } else {
        groupingsFilterHash[grouping].push(scoreBand);
      }
    });
    let tempFilteredSchools = (search) ? schoolBaseList.filter((school) => school.name.toLowerCase().includes(search.toLowerCase())) : schoolBaseList;
    for (const grouping in groupingsFilterHash) {
      tempFilteredSchools = tempFilteredSchools.filter((schoolObj) => {
        return groupingsFilterHash[grouping].some((scoreBand) => {
          const scoreBandsHash = (isElem) ? scoreBandsHashElem : (isClimate) ? scoreBandsHashClimate : (isHighSchool) ? scoreBandHashHighSchool : {};
          const minScore = scoreBandsHash[scoreBand]?.min || 0;
          const maxScore = scoreBandsHash[scoreBand]?.max || Infinity;
          const schoolScore = schoolObj[grouping];
          return schoolScore >= minScore && schoolScore < maxScore;
        });
      });
    };
    tempFilteredSchools = _.orderBy(tempFilteredSchools, sortKey, sortDirection);
    setSchools(tempFilteredSchools);
  }, [
    filterList,
    sortKey,
    sortDirection,
    schoolBaseList,
    search,
    assessment,
  ]);

  if (onload) return <LoadingPane />;

  return (
    <div className='summary-school-view full-screen-view'>
      <ul className='times-frame'>
        {generateTimepoints().map((timepoint) => {
          const selected = (time === timepoint);

          return (
            <Checkbox
              key={timepoint}
              onChange={() => {
                setTime(timepoint);
                setInSummaryPlot(false);
                setParentTimepoint(timepoint);
              }}
              checked={selected}
              title={`Time ${timepoint}`}
              type='radio'
            />
          );
        })}
      </ul>

      <div className='table-frame'>
        <div className='table-toolbar-frame'>
          <SearchField
            placeholder='School Name'
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />

          {filterList.length > 0 &&
            <button
            className='clear-all-filters-btn'
            onClick={() => setFilterList([])}>
            CLEAR FILTERS</button>}

            <CSVLink
              className='table-toolbar-button'
              data={csvData}
              filename={`${assessment.name.toUpperCase()} ${(school ? school?.name : district?.name).toUpperCase()} ${new Date().toLocaleDateString('en-US')}`.replace(/ /g, '-')}
            >export report</CSVLink>
        </div>

        <TableView
          headers={[
            {
              label: 'School',
              key: 'name',
            },
            {
              label: 'Number of Students',
              key: 'studentCount',
            },
            ...categories.map((c) => ({
              label: c.name,
              key: c.key,
            })),
          ]}
          data={schools}
          assessment={assessment}
          dataMappings={[
            { key: 'name' },
            { key: 'studentCount' },
            ...categories.map((c) => ({
              key: c.key,
              score: c.key,
            })),
          ]}
          stickyNode={stickyNode}
          sortKey={sortKey}
          sortDirection={sortDirection}
          onSort={handleSort}
          onFilter={handleCheckboxChange}
          filterList={filterList}
        />

        <SummaryTooltip
          isClimate={isClimateSurvey(assessment)}
          isHighSchool={[
            '5',
            '84',
            '85',
          ].includes(assessment.id)}
        />
      </div>

      <div style={{ padding: 20 }}/>
    </div>
  );
};

// const fauxData = [
//   {
//     id: 1,
//     school: 'Jefferson Elementary',
//     students: 90,
//     score: 125,
//     er: 50,
//     spt: 85,
//     sps: 102,
//     sc: 131,
//   },
//   {
//     id: 2,
//     school: 'Madison Elementary',
//     students: 50,
//     score: 115,
//     er: 50,
//     spt: 110,
//     sps: 105,
//     sc: 65,
//   },
//   {
//     id: 3,
//     school: 'Lincoln Elementary',
//     students: 120,
//     score: 87,
//     er: 100,
//     spt: 120,
//     sps: 90,
//     sc: 55,
//   },
// ];

SummarySchool.propTypes = {
  assessment: PropTypes.object,
  district: PropTypes.object,
  stickyNode: PropTypes.object,
  hasDistrict: PropTypes.bool,
  school: PropTypes.object,
  schoolsList: PropTypes.array,
  setParentTimepoint: PropTypes.func,
  setInSummaryPlot: PropTypes.func,
};

export default SummarySchool;
