import PropTypes from 'prop-types';
import ToolTip from '../../../ToolTip';

const SummaryTooltip = ({ isClimate, isHighSchool }) => {
  return (
    <ToolTip
      data={isClimate ? climate : isHighSchool ? highSchool : defaultLegend}
    />
  );
};

const defaultLegend = [
  {
    hex: '#F5AD52',
    title: 'Well below average',
    subtitle: '< 70',
  },
  {
    hex: '#FED693',
    title: 'Below Average',
    subtitle: '70 - 89',
  },
  {
    hex: '#BBE4D1',
    title: 'At or around average',
    subtitle: '90 - 114',
  },
  {
    hex: '#76C7BE',
    title: 'Above Average',
    subtitle: '≥ 115',
  },
];

const climate = [
  {
    hex: '#F5AD52',
    title: 'Disagree A lot',
    subtitle: 'Score = 1',
  },
  {
    hex: '#FED693',
    title: 'Disagree A little',
    subtitle: 'Score = 2',
  },
  {
    hex: '#BBE4D1',
    title: 'Agree A little',
    subtitle: 'Score = 3',
  },
  {
    hex: '#76C7BE',
    title: 'Agree A lot',
    subtitle: 'Score = 4',
  },
];

const highSchool = [
  {
    hex: '#F5AD52',
    title: 'Not Confident',
    subtitle: 'Score = 1',
  },
  {
    hex: '#FED693',
    title: 'Not Very Confident',
    subtitle: 'Score = 2',
  },
  {
    hex: '#B7B7B7',
    title: 'Sometimes Confident',
    subtitle: 'Score = 3',
  },
  {
    hex: '#BBE4D1',
    title: 'Confident',
    subtitle: 'Score = 4',
  },
  {
    hex: '#76C7BE',
    title: 'Highly Confident',
    subtitle: 'Score = 5',
  },
];

SummaryTooltip.propTypes = {
  isClimate: PropTypes.bool,
  isHighSchool: PropTypes.bool,
};

export default SummaryTooltip;
