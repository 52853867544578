import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.

// const colors = {
//   aa: '#76C7BE',
//   avg: '#BBE4D1',
//   ba: '#FED693',
//   wba: '#F5AD52',
// };

const presetData = [
  {
    label: 'Overall Score',
    sandwich: 30,
    kebab: 30,
    fries: 25,
    donut: 15,
  },
  {
    label: 'Emotion Recognition',
    sandwich: 10,
    kebab: 15,
    fries: 35,
    donut: 40,
  },
  {
    label: 'Social Perspective-Taking',
    sandwich: 25,
    kebab: 15,
    fries: 25,
    donut: 35,
  },
  {
    label: 'Social Problem-Solving',
    sandwich: 15,
    kebab: 15,
    fries: 25,
    donut: 45,
  },
  {
    label: 'Self Control',
    sandwich: 20,
    kebab: 20,
    fries: 50,
    donut: 10,
  },
];

const fourColors = [
  '#F5AD52',
  '#FED693',
  '#BBE4D1',
  '#76C7BE',
];

const fiveColors = [
  '#F5AD52',
  '#FED693',
  '#B7B7B7',
  '#BBE4D1',
  '#76C7BE',
];

const BarChart = ({
  data = presetData, enableXLabel, topLabel, node, keys,
}) => {
  return (
    <ResponsiveBar
      barComponent={(p) => BarComponent({
        ...p,
        barData: data,
        keys,
      })}
      colors={(keys.length === 5) ? fiveColors : fourColors}
      data={data}
      gridYValues={[
        0,
        25,
        50,
        75,
        100,
      ]}
      indexBy="label"
      keys={keys || []}
      margin={{
        top: topLabel ? 110 : 10,
        right: 10,
        bottom: 85,
        left: enableXLabel ? 100 : 50,
      }}
      padding={0.3}
      axisTop={{
        tickSize: 0,
        renderTick: () => <div />,
        legend: topLabel,
        legendPosition: 'middle',
        legendOffset: -50,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 15,
        tickRotation: 0,
        renderTick: (data) => {
          const {
            textX, textY, value, x, y,
          } = data;
          const nodeWidth = (node?.current?.clientWidth / 2);
          const pageWidth = window.innerWidth;
          const width = node ? nodeWidth : pageWidth;

          function calculateSize (sm, med, lg) {
            if (width < 600) {
              return sm;
            } else if (width < 800) {
              return med;
            } else {
              return lg;
            }
          }

          return (
            <g transform={`translate(${x},${y})`}>
              <foreignObject
                transform={`translate(${textX - (calculateSize(30, 50, 60))},${textY})`}
                style={{
                  width: calculateSize(60, 100, 120),
                  height: 80,
                  fontSize: calculateSize(8.5, 10, 13),
                  color: '#8A94A6',
                  wordWrap: 'break-word',
                  textAlign: 'center',
                }}
              >
                {value}
              </foreignObject>
            </g>
          );
        },
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        tickValues: [
          0,
          25,
          50,
          75,
          100,
        ],
        legend: enableXLabel ? 'Percent of student responses' : '',
        legendPosition: 'middle',
        legendOffset: -60,
      }}

      // margin={{ top: 110, right: 30, bottom: 80, left: 120 }}
      // legends={[
      //     {
      //         anchor: 'bottom-right',
      //         direction: 'column',
      //         translateX: 0,
      //         itemWidth: 100,
      //         itemHeight: 20,
      //         symbolSize: 20
      //     }
      // ]}
      theme={{
        axis: {
          ticks: {
            text: {
              width: '10px',
              fill: '#8A94A6',
              fontSize: 12,
              textWrap: 'wrap',
            },
          },
          legend: {
            text: {
              fontSize: 12,
              fontWeight: 'bold',
              fill: '#4E5D78',
            },
          },
        },
        grid: {
          line: {
            stroke: '#D8DFEC',
            strokeWidth: 1.5,
            strokeDasharray: '4 4',
          },
        },
      }}
      tooltip={(bar) => {
        const { color, label } = bar;

        return (
          <div>
            <div style={{ color }}>{label}</div>
          </div>
        );
      }}
    />
  );
};

BarChart.propTypes = {
  enableXLabel: PropTypes.bool,
  topLabel: PropTypes.string,
  node: PropTypes.element,
  keys: PropTypes.array,
  data: PropTypes.array,
};

const BarComponent = (props) => {
  const {
    bar: {
      index, x, y, width, height, color,
    }, barData, keys, label, node,
  } = props;
  const barAmt = (barData.length * keys.length); // amount of bars we are showing
  const showHeader = (index >= ((barAmt - keys.length) - 1));

  const nodeWidth = (node?.current?.clientWidth / 2);
  const pageWidth = window.innerWidth;
  const __width = node ? nodeWidth : pageWidth;

  function calculateSize (sm, med, lg) {
    if (__width < 600) {
      return sm;
    } else if (__width < 800) {
      return med;
    } else {
      return lg;
    }
  }

  const offsetX = (label.length === 3) ? -3 : 0; // handle 100% labels

  return (
    <g transform={`translate(${x}, ${y})`} /* onMouseEnter={() => { console.log('test'); }} */>
      <rect
        width={width}
        height={height}
        fill={color}
        strokeWidth="0"
        stroke="white"
      />

      {showHeader &&
        <text
          x={width / 2}
          y={-20} // the important bit!!
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: calculateSize(10, 10, 12),
            fill: '#8A94A6',
          }}
        >
        </text>
      }

      {(label > 5) &&
        <text
          x={calculateSize((width - 21 + offsetX) / 2, (width - 21 + offsetX) / 2, (width - 24 + offsetX) / 2)}
          y={height / 2}
          textAnchor='center'
          dominantBaseline="central"
          style={{
            fontSize: calculateSize(10, 10, 12),
            fill: '#4E5D78',
          }}
        >
          {`${label}%`}
        </text>
      }
    </g>
  );
};

BarComponent.propTypes = {
  bar: PropTypes.object,
  label: PropTypes.string,
  node: PropTypes.object,
  keys: PropTypes.array,
  barData: PropTypes.array,
};

export default BarChart;
